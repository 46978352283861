import React, { useEffect, useRef, useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { Translation } from '../../../helpers/Translation'
import {
  getGridColor,
  getPathColor,
  hasClass,
} from '../../../helpers/Utils'
import {
  HIDE_DROPDOWN_TIMEOUT,
  CONTROLS_DEFAULT_STATE,
  ALL_LAYERS_KEY,
  PATH_WIDTH_MIN,
  PATH_WIDTH_MAX,
  SHOW_PATH_PREVIEW_CLASS,
  PREVIEW_GRID_CLASS,
  ACTIVE_CLASS,
  PATH_DISPLAY_RATIO,
  IMAGE_TYPES,
  DISABLED_CLASS,
  INTERVAL_1_SECOND,
  NO_UNDERLAY_KEY,
  PX_TO_REM,
  TRANSPARENT_KEY,
  TRANSPARENT_BG,
  PATH_COLORS,
  PREVIEW_BG_COLORS,
} from '../../../helpers/Constants'

import Range from '../../../components/Range'
import Checkbox from '../../../components/Checkbox'
import Toggle from '../../../components/Toggle'
import Tooltip from '../../../components/Tooltip'
import ColorPicker from '../../../components/ColorPicker'

import ImageSizeRange from '../ImageSizeRange'

import { ReactComponent as MenuImageSizeSvg } from '../../../svg/menu_image_size.svg'
import { ReactComponent as MenuChangeBackgroundSvg } from '../../../svg/menu_change_background.svg'
import { ReactComponent as MenuDisplaySettingsSvg } from '../../../svg/menu_display_settings.svg'
import { ReactComponent as MenuShowGridsSvg } from '../../../svg/menu_show_grids.svg'
import { ReactComponent as MenuShowClippingPathsSvg } from '../../../svg/menu_show_clipping_paths.svg'
import { ReactComponent as MenuShowLayersSvg } from '../../../svg/menu_show_layers.svg'
import { ReactComponent as PreviewTypeGallerySvg } from '../../../svg/preview_type_gallery.svg'
import { ReactComponent as LayerRadioButtonIconUnselectedSvg } from '../../../svg/layer_radio_button_unselected.svg'
import { ReactComponent as LayerRadioButtonIconSelectedSvg } from '../../../svg/layer_radio_button_selected.svg'
import { ReactComponent as BackgroundDropdown1Svg } from '../../../svg/background_dropdown_1.svg'
import { ReactComponent as BackgroundDropdown2Svg } from '../../../svg/background_dropdown_2.svg'
import { ReactComponent as OverlayBackgroundTransparentSvg } from '../../../svg/overlay_background_transparent.svg'

import './index.scss'

const Controls = () => {
  const layoutState = useStoreState((state) => ({
    controlsState: state.layout.controlsState,
    pathsColors: state.layout.pathsColors,
  }))

  const layoutActions = useStoreActions((actions) => ({
    updateControlsState: actions.layout.updateControlsState,
    updatePathsColors: actions.layout.updatePathsColors,
  }))

  const orderState = useStoreState((state) => ({
    gallery: state.order.gallery,
    orderId: state.order.orderId,
    imageType: state.order.imageType,
    imageSize: state.order.imageSize,
    imageSort: state.order.imageSort,
    images: state.order.images,
    paths: state.order.paths,
    pathRange: state.order.pathRange,
    selectedImagePaths: state.order.selectedImagePaths,
    layerUrls: state.order.layerUrls,
    selectedLayer: state.order.selectedLayer,
    selectedLayers: state.order.selectedLayers,
    selectedGrids: state.order.selectedGrids,
    selectedProperties: state.order.selectedProperties,
    selectedUnderlay: state.order.selectedUnderlay,
    selectedUnderlayUrl: state.order.selectedUnderlayUrl,
    selectedUnderlayWidth: state.order.selectedUnderlayWidth,
    selectedUnderlayHeight: state.order.selectedUnderlayHeight,
    galleryBackground: state.order.galleryBackground,
    previewsBackground: state.order.previewsBackground,
    isPreviewsBottomSpace: state.order.isPreviewsBottomSpace,
  }))

  const orderActions = useStoreActions((actions) => ({
    setImageSort: actions.order.setImageSort,
    setImageSize: actions.order.setImageSize,
    getPath: actions.order.getPath,
    setPathRange: actions.order.setPathRange,
    setSelectedImagePaths: actions.order.setSelectedImagePaths,
    getLayer: actions.order.getLayer,
    setSelectedLayer: actions.order.setSelectedLayer,
    setAllSelectedLayers: actions.order.setAllSelectedLayers,
    setSelectedGrids: actions.order.setSelectedGrids,
    setSelectedProperties: actions.order.setSelectedProperties,
    setSelectedUnderlay: actions.order.setSelectedUnderlay,
    setSelectedUnderlayUrl: actions.order.setSelectedUnderlayUrl,
    setSelectedUnderlayWidth: actions.order.setSelectedUnderlayWidth,
    setSelectedUnderlayHeight: actions.order.setSelectedUnderlayHeight,
    setGalleryBackground: actions.order.setGalleryBackground,
    setPreviewsBackground: actions.order.setPreviewsBackground,
    setIsPreviewsBottomSpace: actions.order.setIsPreviewsBottomSpace,
  }))

  const properties = [
    { name: 'filename', label: Translation.filename },
    { name: 'folder', label: Translation.folder },
    { name: 'resolution', label: Translation.resolution },
    { name: 'dpi', label: Translation.dpi.toUpperCase() },
    { name: 'data_sizes', label: Translation.filesize },
    { name: 'colour_space', label: Translation.colour_space },
    { name: 'background_colour', label: Translation.background_colour },
    { name: 'colour_depth', label: Translation.colour_depth },
    { name: 'paths', label: Translation.paths },
    { name: 'layers', label: Translation.layers },
    { name: 'exif_data', label: Translation.exif_data },
    { name: 'meta_data', label: Translation.meta_data },
    { name: 'ai_labels', label: Translation.ai_labels },
  ]

  const isMouseOverRef = useRef(false)
  const isOpenedRef = useRef(false)
  const [shortenNames, setShortenNames] = useState(true)
  const [previewSize, setPreviewSize] = useState('gallery') // gallery or list
  const [gallerySize, setGallerySize] = useState('imageSize') // imageSize or grid
  const [gridSize, setGridSize] = useState(1)
  const [selectedImagePaths, setSelectedImagePaths] = useState([])
  const [isColorPickerOpened, setIsColorPickerOpened] = useState({
    pathColor: false,
    galleryBackground: false,
    previewsBackground: false,
  })
  const [colorSelectingPath, setColorSelectingPath] = useState(null)
  const [isLayers, setIsLayers] = useState(false)
  const [isMasks] = useState(false)
  const [isUnderlays, setIsUnderlays] = useState(false)

  useEffect(() => {
    isOpenedRef.current = Object.values(layoutState.controlsState).some((value) => value)
  }, [layoutState.controlsState])

  useEffect(() => {
    document.querySelectorAll(`.${SHOW_PATH_PREVIEW_CLASS}`).forEach((path) => {
      path?.querySelector('path').setAttribute('stroke-width', orderState.pathRange / PATH_DISPLAY_RATIO)
    })
  }, [orderState.pathRange])

  useEffect(() => {
    document.querySelectorAll(`.${SHOW_PATH_PREVIEW_CLASS}`).forEach((path) => {
      if (layoutState.pathsColors[path.dataset.pathName]) {
        path.querySelector('path').setAttribute('stroke', layoutState.pathsColors[path.dataset.pathName])
      }
    })
  }, [layoutState.pathsColors])

  useEffect(() => {
    if (!orderState.gallery) return
    const defaultSelectedGrids = orderState.gallery?.grids?.filter((grid) => grid?.is_show)
    const newSelectedGrids = [...orderState.selectedGrids, ...(defaultSelectedGrids || [])]
    orderActions.setSelectedGrids(newSelectedGrids)
  }, [orderState.gallery?.grids?.length])

  useEffect(() => {
    if (!orderState.gallery) return
    setIsLayers(Object.keys(orderState?.gallery?.layer_names)?.length > 0)
  }, [orderState.gallery?.layer_names])

  useEffect(() => {
    if (!orderState.gallery) return
    setIsUnderlays(Object.keys(orderState?.gallery?.underlays)?.length > 0)
  }, [orderState.gallery?.underlays])

  useEffect(() => {
    orderActions.setSelectedProperties(
      localStorage.getItem('selectedProperties')
        ? JSON.parse(localStorage.getItem('selectedProperties'))
        : properties,
    )
  }, [])

  const onHandleMenuToggle = (e) => {
    e.stopPropagation()

    if (!hasClass(e.currentTarget, 'header--controls__icon')) return

    layoutActions.updateControlsState({
      ...CONTROLS_DEFAULT_STATE,
      [e.currentTarget.id]: !layoutState.controlsState[e.currentTarget.id],
    })
  }

  const onHandleMouseLeave = () => {
    isMouseOverRef.current = false

    setTimeout(() => {
      if (isOpenedRef.current && !isMouseOverRef.current) {
        layoutActions.updateControlsState(CONTROLS_DEFAULT_STATE)
      }
    }, HIDE_DROPDOWN_TIMEOUT)
  }

  const onHandleMouseEnter = () => {
    isMouseOverRef.current = true
  }

  // const onHandleImageSize = (e) => {
  //   e.preventDefault()

  //   const imageSizeUpdate = parseInt(e.currentTarget.dataset.imageSizeUpdate, 10)

  //   orderActions.setImageSize(imageSizeUpdate)

  //   navigate(getGalleryUrl(
  //     userState.user.is_admin,
  //     orderState.imageType,
  //     orderState.orderId,
  //     imageSizeUpdate,
  //     orderState.imageSort,
  //   ))
  // }

  const onChangePathRange = (e) => {
    const { value } = e.target
    orderActions.setPathRange(parseInt(value, 10))
  }

  const onSelectGrid = (grid) => {
    const newSelectedGrids = [...orderState.selectedGrids]
    const index = newSelectedGrids.findIndex((selectedGrid) => selectedGrid.name === grid.name)
    if (index > -1) {
      newSelectedGrids.splice(index, 1)
      document
        .querySelectorAll(`.${PREVIEW_GRID_CLASS}.grid-${orderState.gallery?.grids?.findIndex((g) => g.name === grid.name)}`)
        ?.forEach((gridElement) => {
          gridElement?.remove()
        })
    } else {
      newSelectedGrids.push(grid)
    }
    orderActions.setSelectedGrids(newSelectedGrids)
  }

  const onSelectProperty = (property) => {
    const newSelectedProperties = [...orderState.selectedProperties]
    const index = newSelectedProperties.findIndex((selectedProperty) => selectedProperty.name === property.name)
    if (index > -1) {
      newSelectedProperties.splice(index, 1)
    } else {
      newSelectedProperties.push(property)
    }
    orderActions.setSelectedProperties(newSelectedProperties)
  }

  const onSelectAllProperties = () => {
    if (properties.length === orderState.selectedProperties.length) {
      orderActions.setSelectedProperties([])
    } else {
      orderActions.setSelectedProperties(properties)
    }
  }

  const pathCount = (path) => {
    let count = 0
    orderState.images?.forEach((image) => {
      if (image.path?.[path]) {
        count += 1
      }
    })
    return count
  }

  const onSelectPath = async (e, path) => {
    setSelectedImagePaths((prevSelectedImagePaths) => {
      const newSelectedImagePaths = [...prevSelectedImagePaths]
      const index = newSelectedImagePaths.findIndex((selectedPath) => selectedPath === path)
      if (index > -1) {
        newSelectedImagePaths.splice(index, 1)
      } else {
        newSelectedImagePaths.push(path)
      }
      return newSelectedImagePaths
    })

    const imageIds = []
    const s3Paths = []
    orderState.images.forEach((image) => {
      if (image.path?.[path]) {
        imageIds.push(image.id)
        s3Paths.push(image.path[path]?.line?.s3_path)
      }
    })

    const selectedImagePathsCopy = { ...orderState.selectedImagePaths }
    orderState.images.forEach((image) => {
      if (!selectedImagePathsCopy[image.id]) selectedImagePathsCopy[image.id] = {}
      selectedImagePathsCopy[image.id][path] = e.currentTarget.checked
    })
    orderActions.setSelectedImagePaths(selectedImagePathsCopy)

    orderState.images.forEach((image) => {
      if (!selectedImagePathsCopy?.[image.id]?.[path]) {
        document.getElementById(`${image.id}-${path}-preview`)?.remove()
      }
    })

    const hasAllPath = imageIds.every((imageId, index) => orderState.paths?.[imageId]?.[s3Paths[index]])
    if (hasAllPath) return

    if (e.currentTarget.checked) {
      await orderActions.getPath({
        image_ids: imageIds,
        s3_paths: s3Paths,
      })
    }
  }

  const onSelectAllPaths = (e) => {
    if (e.currentTarget.checked) {
      let pathIndex = 0
      for (let i = 0; i < orderState.gallery.path_names.length; i += 1) {
        const pathName = orderState.gallery.path_names[i]
        if (!selectedImagePaths.includes(pathName)) {
          // eslint-disable-next-line no-loop-func
          (() => {
            setTimeout(() => {
              onSelectPath({ currentTarget: { checked: true } }, pathName).then(() => {
              })
            }, pathIndex * 30)
          })(pathIndex += 1)
        }
      }
    } else {
      for (let i = 0; i < orderState.gallery.path_names.length; i += 1) {
        onSelectPath({ currentTarget: { checked: false } }, orderState.gallery.path_names[i]).then(() => {
        })
      }
    }
  }

  useEffect(() => {
    if (orderState.images?.length > 0
      && orderState.gallery?.path_names?.length > 0
      && orderState.gallery?.is_path_in_actions
      && (orderState.imageType === IMAGE_TYPES.compare || orderState.imageType === IMAGE_TYPES.output)) {
      setTimeout(() => {
        onSelectPath({ currentTarget: { checked: true } }, orderState.gallery?.path_names[0]).then(() => {
        })
      }, INTERVAL_1_SECOND)
    }

    if (orderState.images?.length > 0 && orderState.gallery?.path_names?.length > 0) {
      const pathsColors = JSON.parse(localStorage.getItem('pathsColors')) || {}
      const defaultPathColors = { ...pathsColors }

      orderState.gallery?.path_names.forEach((pathName, index) => {
        if (!defaultPathColors[pathName]) {
          defaultPathColors[pathName] = getPathColor(index)
        }
      })

      layoutActions.updatePathsColors(defaultPathColors)
    }
  }, [
    orderState.gallery?.is_path_in_actions,
    orderState.imageType,
    orderState.gallery?.path_names,
    orderState.images,
  ])

  const onHandleSelectAllLayers = () => {
    const selectedLayers = {}
    orderState.images.forEach((image) => {
      selectedLayers[image.id] = `${ALL_LAYERS_KEY}-${image.id}`
    })
    orderActions.setAllSelectedLayers(selectedLayers)
    orderActions.setSelectedLayer(ALL_LAYERS_KEY)
  }

  const onHandleSelectLayer = (e) => {
    const selectedLayers = { ...orderState.selectedLayers }
    orderState.images.forEach((image) => {
      const layer = image.layer?.find((l) => l.name === e.target.value)
      if (layer) selectedLayers[image.id] = layer.id
    })
    orderActions.setAllSelectedLayers(selectedLayers)
    orderActions.setSelectedLayer(e.target.value)
  }

  const onHandleSelectUnderlay = (e) => {
    orderActions.setSelectedUnderlayUrl(e.target.dataset.underlayUrl)
    orderActions.setSelectedUnderlayWidth(e.target.dataset.underlayWidth)
    orderActions.setSelectedUnderlayHeight(e.target.dataset.underlayHeight)
    orderActions.setSelectedUnderlay(e.target.value)
  }

  const closePicker = () => {
    setColorSelectingPath(null)
    setIsColorPickerOpened({
      pathColor: false,
      galleryBackground: false,
      previewsBackground: false,
    })
  }

  const handlePathColorClick = (path) => {
    setColorSelectingPath(path)
    setIsColorPickerOpened({
      pathColor: true,
      galleryBackground: false,
      previewsBackground: false,
    })
  }

  const handlePathColorSelect = (color) => {
    layoutActions.updatePathsColors({
      ...layoutState.pathsColors,
      [colorSelectingPath]: color,
    })
    setColorSelectingPath(null)
    closePicker()
  }

  const handleGalleryBGColorSelect = (color) => {
    orderActions.setGalleryBackground(color)
    closePicker()
  }

  const handlePreviewsBGColorSelect = (color) => {
    orderActions.setPreviewsBackground(color)
    closePicker()
  }

  return (
    <div
      className="header--wraps header--controls__wrap"
      onMouseLeave={onHandleMouseLeave}
      onMouseEnter={onHandleMouseEnter}
    >
      <div className="menu--header header--controls__control">
        <Tooltip
          position="left"
          text={Translation.select_image_size}
        >
          <div onClick={onHandleMenuToggle} id="imageSize" className="header--controls__icon">
            <MenuImageSizeSvg />
          </div>
        </Tooltip>

        <div
          className="header--controls__container preview-type-container scrollbar-overflow scrollbar-overflow__small"
          style={{ display: layoutState.controlsState.imageSize ? '' : 'none' }}
        >
          <p className="container-title roobert-medium">{Translation.preview_type}</p>

          <div className="preview-type">
            <div className="left">
              <div className="left-top">
                <input
                  type="radio"
                  className="preview-type-radio"
                  id="previewTypeGallery"
                  name="previewType"
                  value="gallery"
                  checked={previewSize === 'gallery'}
                  onChange={() => {
                    setPreviewSize('gallery')
                  }}
                />
                <label htmlFor="previewTypeGallery" className="radio-icon">
                  {previewSize === 'gallery' ? <LayerRadioButtonIconSelectedSvg />
                    : <LayerRadioButtonIconUnselectedSvg />}
                </label>
                <label htmlFor="previewTypeGallery" className="preview-type--label">{Translation.gallery}</label>
              </div>
              <div className="left-bottom">
                <PreviewTypeGallerySvg />
              </div>
            </div>
            <div className="right">
              <div className="right-top">
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation()
                    setGallerySize('imageSize')
                  }}
                  className={`btn-gallery-size roobert-medium ${gallerySize === 'imageSize' ? ACTIVE_CLASS : ''}`}
                >
                  {Translation.use_image_size}
                </button>
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation()
                    setGallerySize('grid')
                  }}
                  className={`btn-gallery-size roobert-medium ${gallerySize === 'grid' ? ACTIVE_CLASS : ''}`}
                >
                  {Translation.use_grid}
                </button>
              </div>
              <div className="right-bottom">
                {gallerySize === 'imageSize' && (
                  <div className="image-size">
                    <ImageSizeRange />
                  </div>
                )}
                {gallerySize === 'grid' && (
                  <div className="grid-size">
                    <p className="grid-size-desc">
                      {Translation.grid_size}
                      :&nbsp;
                      <span>{gridSize}</span>
                    </p>
                    <Range
                      value={gridSize}
                      min={1}
                      max={12}
                      showLabels
                      onChange={(e) => {
                        e.preventDefault()
                        setGridSize(parseInt(e.target.value, 10))
                      }}
                    />
                    <p className="notice">{Translation.notice_images_expand}</p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="preview-type list">
            <div className="left">
              <div className="left-top">
                <input
                  type="radio"
                  className="preview-type-radio"
                  id="previewTypeList"
                  name="previewType"
                  value="list"
                  checked={previewSize === 'list'}
                  onChange={() => {
                    setPreviewSize('list')
                  }}
                />
                <label htmlFor="previewTypeList" className="radio-icon">
                  {previewSize === 'list' ? <LayerRadioButtonIconSelectedSvg /> : <LayerRadioButtonIconUnselectedSvg />}
                </label>
                <label htmlFor="previewTypeList" className="preview-type--label">{Translation.list}</label>
              </div>
              <div className="left-bottom">
                <PreviewTypeGallerySvg />
              </div>
            </div>
            <div className="right">
              <p className="description">{Translation.in_list_view}</p>
            </div>
          </div>
        </div>

        {/* <ul id="sizes-menu--list" className="menu--list" style={{ display: layoutState.controlsState.imageSize
          ? '' : 'none' }}>
          <li className={`menu__item ${IMAGE_SIZES.small === orderState.imageSize ? 'menu__item--active' : ''}`}>
            <a href="#" onClick={onHandleImageSize} data-image-size-update={IMAGE_SIZES.small}>
              {Translation.gallery_small}
            </a>
          </li>
          <li className={`menu__item ${IMAGE_SIZES.medium === orderState.imageSize ? 'menu__item--active' : ''}`}>
            <a href="#" onClick={onHandleImageSize} data-image-size-update={IMAGE_SIZES.medium}>
              {Translation.gallery_medium}
            </a>
          </li>
          <li className={`menu__item ${IMAGE_SIZES.large === orderState.imageSize ? 'menu__item--active' : ''}`}>
            <a href="#" onClick={onHandleImageSize} data-image-size-update={IMAGE_SIZES.large}>
              {Translation.gallery_grid}
            </a>
          </li>
          <li className="menu__item" style={{ display: 'none' }}>
            <a href="#">
              {Translation.gallery_list}
            </a>
          </li>
        </ul> */}
      </div>

      <div className="menu--header header--controls__control">
        <Tooltip
          position="left"
          text={Translation.change_background}
        >
          <div onClick={onHandleMenuToggle} id="changeBackground" className="header--controls__icon">
            <MenuChangeBackgroundSvg />
          </div>
        </Tooltip>

        <div
          className="header--controls__container background-container scrollbar-overflow scrollbar-overflow__small"
          style={{ display: layoutState.controlsState.changeBackground ? '' : 'none' }}
        >
          <div className="left">
            <p className="container-title roobert-medium">{Translation.background}</p>

            <div className="background-type-container">
              <div
                className="color"
                style={{ backgroundColor: orderState.galleryBackground }}
                onClick={() => setIsColorPickerOpened({ ...isColorPickerOpened, galleryBackground: true })}
              >
                {isColorPickerOpened.galleryBackground && (
                  <ColorPicker
                    onColorChange={handleGalleryBGColorSelect}
                    closePicker={closePicker}
                    colors={orderState.gallery?.gallery_backgrounds
                      .map((bg) => bg.color) || []}
                    labels={orderState.gallery?.gallery_backgrounds
                      .filter((bg) => bg.is_format_background)
                      .map((bg, index) => `F${index + 1}`) || []}
                    tooltips={orderState.gallery?.gallery_backgrounds
                      .map((bg) => bg.name) || []}
                    isTransparentDisabled
                    customColorType="pickerColorBg"
                  />
                )}
              </div>
              <span className="gallery-background">
                {Translation.gallery_background}
              </span>
            </div>

            <Toggle
              checked={orderState.isPreviewsBottomSpace}
              onChange={(e) => {
                orderActions.setIsPreviewsBottomSpace(e.currentTarget.checked)
              }}
              id="bottom-spacing"
              label={Translation.bottom_spacing}
            />

            <div className="separator" />

            <div className="background-type-container">
              <div
                className="color"
                style={{ backgroundColor: orderState.previewsBackground }}
                onClick={() => setIsColorPickerOpened({ ...isColorPickerOpened, previewsBackground: true })}
              >
                {orderState.previewsBackground === TRANSPARENT_KEY && <OverlayBackgroundTransparentSvg />}
                {isColorPickerOpened.previewsBackground && (
                  <ColorPicker
                    onColorChange={handlePreviewsBGColorSelect}
                    closePicker={closePicker}
                    colors={PREVIEW_BG_COLORS}
                    customColorType="pickerColorBg"
                  />
                )}
              </div>
              <span className="gallery-background">
                {Translation.image_background}
              </span>
            </div>
          </div>
          <div
            className="right"
            style={{ backgroundColor: orderState.galleryBackground }}
          >
            <BackgroundDropdown1Svg
              style={
                orderState.previewsBackground === TRANSPARENT_KEY
                  ? { backgroundImage: `url(${TRANSPARENT_BG})` }
                  : { backgroundColor: orderState.previewsBackground }
              }
            />
            <BackgroundDropdown2Svg />
          </div>

        </div>
      </div>

      <div className="menu--header header--controls__control">
        <Tooltip
          position="left"
          text={Translation.display_settings}
        >
          <div onClick={onHandleMenuToggle} id="displaySettings" className="header--controls__icon">
            <MenuDisplaySettingsSvg />
          </div>
        </Tooltip>

        <div
          className="header--controls__container properties-container scrollbar-overflow scrollbar-overflow__small"
          style={{ display: layoutState.controlsState.displaySettings ? '' : 'none' }}
        >
          <p className="container-title">{Translation.properties}</p>

          <div className="shorten">
            <Toggle
              checked={shortenNames}
              onChange={() => setShortenNames((prevShortenNames) => !prevShortenNames)}
              id="shorten_names"
              label={Translation.shorten_names}
            />
          </div>

          <div className="separator" />

          <div className="property">
            <Checkbox
              id="select-all-properties"
              label={Translation.select_all}
              checked={properties.length === orderState.selectedProperties.length}
              onChange={onSelectAllProperties}
              halfChecked={orderState.selectedProperties.length > 0 && orderState.selectedProperties.length < properties.length}
            />
          </div>

          {properties.map((property) => (
            <div className="property" key={property.name}>
              <Checkbox
                label={property.label}
                id={`property-${property.name}`}
                checked={orderState.selectedProperties.some((selectedProperty) => selectedProperty.name === property.name)}
                onChange={() => onSelectProperty(property)}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="menu--header header--controls__control">
        <Tooltip
          position="left"
          text={Translation.show_grids}
        >
          <div onClick={onHandleMenuToggle} id="showGrids" className="header--controls__icon">
            <MenuShowGridsSvg />
          </div>
        </Tooltip>

        <div
          className="header--controls__container grids-container scrollbar-overflow scrollbar-overflow__small"
          style={{ display: layoutState.controlsState.showGrids ? '' : 'none' }}
        >
          <p className="container-title roobert-medium">{Translation.grids}</p>
          {orderState.gallery?.grids?.length > 0 && (
            orderState.gallery?.grids.map((grid, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index} style={{ width: '100%' }}>
                <div className="grid-list-item">
                  <div
                    className="grid-item-color"
                    style={{
                      backgroundColor:
                        orderState.selectedGrids.some((selectedGrid) => selectedGrid.name === grid.name)
                          ? getGridColor(index)
                          : null,
                    }}
                  />
                  <Checkbox
                    label={(
                      <span className="overflow-ellipsis">
                        {grid.name || grid.name_short}
                        {' '}
                        <span className="grid-item-template-name">
                          {grid.template_name}
                        </span>
                      </span>
                    )}
                    id={`grid-control-${orderState.gallery?.grids?.findIndex((g) => g.name === grid.name)}`}
                    checked={orderState.selectedGrids.some((selectedGrid) => selectedGrid.name === grid.name)}
                    onChange={() => onSelectGrid(grid)}
                  />
                </div>

                {grid.name === Translation.grid_horizontal_small && (<div className="separator" />)}
              </div>
            ))
          )}
          {/* <p className="show-more">{Translation.show_more}</p> */}
        </div>
      </div>

      <div
        className={`menu--header header--controls__control ${orderState.gallery?.path_names?.length > 0 ? '' : DISABLED_CLASS}`}
      >
        <Tooltip
          position="left"
          text={(orderState.gallery && Object.keys(orderState.gallery?.path_names)?.length > 0)
            ? Translation.show_clipping_paths : Translation.no_paths_found}
        >
          <div onClick={onHandleMenuToggle} id="showClippingPaths" className="header--controls__icon">
            <MenuShowClippingPathsSvg />
          </div>
        </Tooltip>

        {orderState.gallery?.path_names?.length > 0 && (
          <div
            className="header--controls__container paths-container overflow-visible"
            style={{ display: layoutState.controlsState.showClippingPaths ? '' : 'none' }}
          >
            <p className="container-title">{Translation.paths}</p>
            <span className="line-width-label">{Translation.path_width}</span>
            <Range
              value={orderState.pathRange}
              min={PATH_WIDTH_MIN}
              max={PATH_WIDTH_MAX}
              showLabels
              onChange={onChangePathRange}
            />
            <div className="separator" />

            <div
              className="paths-check-list scrollbar-overflow scrollbar-overflow__small"
              style={{ ...(isColorPickerOpened.pathColor && { minHeight: `${PX_TO_REM['100']}rem` }) }}
            >
              {orderState.gallery?.path_names?.length > 1 && (
                <div className="path-item">
                  <Checkbox
                    id="select-all-paths"
                    label={Translation.select_all}
                    checked={selectedImagePaths.length === orderState.gallery?.path_names?.length}
                    onChange={onSelectAllPaths}
                    halfChecked={
                      selectedImagePaths.length > 0 && selectedImagePaths.length < orderState.gallery?.path_names?.length
                    }
                  />
                </div>
              )}

              {orderState.gallery?.path_names.map((pathName, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} className="path-item">
                  <div
                    className="path-color"
                    style={{
                      backgroundColor: layoutState.pathsColors[pathName],
                    }}
                    onClick={() => handlePathColorClick(pathName)}
                  >
                    {isColorPickerOpened.pathColor && colorSelectingPath === pathName && (
                    <ColorPicker
                      onColorChange={handlePathColorSelect}
                      closePicker={closePicker}
                      colors={PATH_COLORS}
                    />
                    )}
                  </div>
                  <Checkbox
                    id={`path-control-${index}`}
                    label={pathName}
                    checked={selectedImagePaths.includes(pathName)}
                    onChange={(e) => onSelectPath(e, pathName)}
                  />
                  <span className="path-item--counter">
                    {pathCount(pathName)}
                    {' '}
                    {pathCount(pathName) === 1 ? Translation.image : Translation.images}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <div
        className={`menu--header header--controls__control ${(isLayers || isMasks || isUnderlays) ? '' : DISABLED_CLASS}`}
      >
        <Tooltip
          position="left"
          text={(isLayers || isMasks || isUnderlays) ? Translation.show_layers : Translation.no_layers_found}
        >
          <div onClick={onHandleMenuToggle} id="showLayers" className="header--controls__icon">
            <MenuShowLayersSvg />
          </div>
        </Tooltip>

        {(isLayers || isMasks || isUnderlays) && (
          <div
            className="header--controls__container layers-container scrollbar-overflow scrollbar-overflow__small"
            style={{ display: layoutState.controlsState.showLayers ? '' : 'none' }}
          >
            <p className="container-title">
              {Translation.layers_channels}
            </p>

            {isLayers && (
              <>
                <p className="container-title">
                  {Translation.layers}
                </p>

                <div className="layer-item">
                  <input
                    type="radio"
                    className="layer-radio"
                    id={ALL_LAYERS_KEY}
                    name="layerRadio"
                    value={ALL_LAYERS_KEY}
                    checked={orderState.selectedLayer === ALL_LAYERS_KEY}
                    onChange={onHandleSelectAllLayers}
                  />
                  <label htmlFor={ALL_LAYERS_KEY} className="radio-icon">
                    {orderState.selectedLayer === ALL_LAYERS_KEY
                      ? <LayerRadioButtonIconSelectedSvg /> : <LayerRadioButtonIconUnselectedSvg />}
                  </label>
                  <label htmlFor={ALL_LAYERS_KEY} className="layer--label">
                    {Translation.all_layers_visible}
                  </label>
                  <span className="layer-count">
                    {Translation.images}
                  </span>
                </div>

                {Object.keys(orderState.gallery?.layer_names).map((key, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={index} className="layer-item">
                    <input
                      type="radio"
                      className="layer-radio"
                      id={`layer-${key}`}
                      name="layerRadio"
                      value={key}
                      checked={orderState.selectedLayer === key}
                      onChange={onHandleSelectLayer}
                    />
                    <label htmlFor={`layer-${key}`} className="radio-icon">
                      {orderState.selectedLayer === key
                        ? <LayerRadioButtonIconSelectedSvg /> : <LayerRadioButtonIconUnselectedSvg />}
                    </label>
                    <label htmlFor={`layer-${key}`} className="layer--label">
                      {key.replace(/&nbsp;/g, ' ')}
                    </label>
                    <span className="layer-count">
                      {orderState.gallery?.layer_names[key]}
                    </span>
                  </div>
                ))}
              </>
            )}

            {isMasks && (
              <>
                <div className="separator" />
                <p className="container-title">{Translation.masks}</p>

                <div className="mask-item">
                  <Toggle
                    checked
                    onChange={() => {
                    }}
                    id="mask-model"
                    label={(
                      <>
                        {Translation.model}
                        &nbsp;
                        <span className="mask-desc">{`(${Translation.layer_mask})`}</span>
                      </>
                    )}
                  />
                  <span className="mask-count">6</span>
                </div>

                <div className="mask-item">
                  <Toggle
                    checked
                    onChange={() => {
                    }}
                    id="mask-alpha"
                    label={(
                      <>
                        {Translation.alpha_1}
                        &nbsp;
                        <span className="mask-desc">{`(${Translation.alpha_channel})`}</span>
                      </>
                    )}
                  />
                  <span className="mask-count">8</span>
                </div>
              </>
            )}

            {isUnderlays && (
              <>
                <div className="separator" />
                <p className="container-title">
                  {Translation.underlays}
                </p>

                <div className="layer-item">
                  <input
                    type="radio"
                    className="layer-radio"
                    id={NO_UNDERLAY_KEY}
                    name="underlayRadio"
                    value={NO_UNDERLAY_KEY}
                    data-underlay-url=""
                    data-underlay-width="0"
                    data-underlay-height="0"
                    checked={orderState.selectedUnderlay === NO_UNDERLAY_KEY}
                    onChange={onHandleSelectUnderlay}
                  />
                  <label htmlFor={NO_UNDERLAY_KEY} className="radio-icon">
                    {orderState.selectedUnderlay === NO_UNDERLAY_KEY
                      ? <LayerRadioButtonIconSelectedSvg /> : <LayerRadioButtonIconUnselectedSvg />}
                  </label>
                  <label htmlFor={NO_UNDERLAY_KEY} className="layer--label">{Translation.no_underlay}</label>
                </div>

                {Object.keys(orderState.gallery?.underlays).map((key) => (
                  <div key={key} className="layer-item">
                    <input
                      type="radio"
                      className="layer-radio"
                      id={`underlay-${key}`}
                      name="underlayRadio"
                      value={key}
                      data-underlay-url={orderState.gallery?.underlays[key].url}
                      data-underlay-width={orderState.gallery?.underlays[key].width}
                      data-underlay-height={orderState.gallery?.underlays[key].height}
                      checked={orderState.selectedUnderlay === key}
                      onChange={onHandleSelectUnderlay}
                    />
                    <label htmlFor={`underlay-${key}`} className="radio-icon">
                      {orderState.selectedUnderlay === key
                        ? <LayerRadioButtonIconSelectedSvg /> : <LayerRadioButtonIconUnselectedSvg />}
                    </label>
                    <label htmlFor={`underlay-${key}`} className="layer--label">
                      {orderState.gallery?.underlays[key].name}
                    </label>
                  </div>
                ))}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default Controls
