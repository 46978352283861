import React from 'react'
import PropTypes from 'prop-types'
import { useStoreState } from 'easy-peasy'

import { IMAGE_TYPES } from '../../../../../helpers/Constants'
import { Translation } from '../../../../../helpers/Translation'

const ImageRatio = ({
  isOriginalImage,
  image,
}) => {
  const userState = useStoreState((state) => ({
    user: state.user.user,
  }))

  const orderState = useStoreState((state) => ({
    imageType: state.order.imageType,
  }))

  return (!isOriginalImage
    && userState.user.is_admin
    && orderState.imageType !== IMAGE_TYPES.input
    && image.template_image_ratio > 0 && (
      <div
        className={
          (image.ratio_error)
            ? 'info info-error'
            : 'info'
        }
      >
        {(
          image.ratio_error ? (
            <span className="image-error">
              {Translation.wrong_ratio}
              :
              {' '}
              {image.image_ratio}
            </span>
          ) : (
            <span>
              {Translation.ratio}
              :
              {' '}
              {image.image_ratio}
            </span>
          )
        )}
      </div>
  )
  )
}

ImageRatio.propTypes = {
  isOriginalImage: PropTypes.bool,
  image: PropTypes.shape({
    ratio_error: PropTypes.bool,
    image_ratio: PropTypes.number,
    template_image_ratio: PropTypes.number,
  }).isRequired,
}

ImageRatio.defaultProps = {
  isOriginalImage: false,
}

export default ImageRatio
