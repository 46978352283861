import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useStoreActions, useStoreState } from 'easy-peasy'

import {
  IMAGE_SIZES,
  INTERVAL_60_SECONDS,
  CLIENT_GALLERY_PREFIX,
  ADMIN_GALLERY_PREFIX,
  IMAGES_PER_PAGE,
  IMAGE_TYPES,
  IMAGES_PER_PAGE_TIMEOUT,
  INTERSECTION_OBSERVER_MARGIN,
  DRAG_SELECT_CLASS,
  NOSELECT_CLASS,
  LOADED_CLASS,
  TRANSPARENT_KEY,
} from '../../helpers/Constants'
import {
  addClass,
  getGalleryUrl,
  isAllImagesLoaded,
  removeClass,
  useEventListener,
} from '../../helpers/Utils'
import { Translation } from '../../helpers/Translation'
import { Cookie } from '../../helpers/Cookie'

import Dialog from '../../components/Dialog'

import Actions from './Actions'
import Dialogs from './Dialogs'
import Download from './Download'
import InfoBar from './InfoBar'
import Overlay from './Overlay'
import Previews from './Previews'

import Loader from '../../layouts/Common/Loader'

import DeleteErrorsDialog from './Dialogs/DeleteErrorsDialog'
import ExplodeOrderDialog from './Dialogs/ExplodeOrderDialog'
import UpdatePricesDialog from './Dialogs/UpdatePricesDialog'

import { ReactComponent as ArrowUpwardSvg } from '../../svg/arrow_upward.svg'

import './index.scss'

const Gallery = () => {
  const userState = useStoreState((state) => ({
    user: state.user.user,
  }))

  const layoutState = useStoreState((state) => ({
    isOverlayOpened: state.layout.isOverlayOpened,
    isInfosOpened: state.layout.isInfosOpened,
    isDialogModalOpened: state.layout.isDialogModalOpened,
    isDeleteErrorsModalOpened: state.layout.isDeleteErrorsModalOpened,
    isExplodeOrderModalOpened: state.layout.isExplodeOrderModalOpened,
    isUpdatePricesModalOpened: state.layout.isUpdatePricesModalOpened,
    isRedoModalOpened: state.layout.isRedoModalOpened,
    isOrderStatusModalOpened: state.layout.isOrderStatusModalOpened,
  }))

  const layoutActions = useStoreActions((actions) => ({
    updateDialogModalOpened: actions.layout.updateDialogModalOpened,
    updateExplodeOrderModalOpened: actions.layout.updateExplodeOrderModalOpened,
    updateUpdatePricesModalOpened: actions.layout.updateUpdatePricesModalOpened,
    updateDeleteErrorsModalOpened: actions.layout.updateDeleteErrorsModalOpened,
    updateRedoModalOpened: actions.layout.updateRedoModalOpened,
  }))

  const orderState = useStoreState((state) => ({
    orderId: state.order.orderId,
    imageType: state.order.imageType,
    imageSize: state.order.imageSize,
    imageSort: state.order.imageSort,
    imageFilter: state.order.imageFilter,
    isCombineExplode: state.order.isCombineExplode,
    images: state.order.images,
    filteredImages: state.order.filteredImages,
    gallery: state.order.gallery,
    links: state.order.links,
    updatedImages: state.order.updatedImages,
    selectedImages: state.order.selectedImages,
    downloadUrls: state.order.downloadUrls,
    selectedLayers: state.order.selectedLayers,
    galleryBackground: state.order.galleryBackground,
    previewsBackground: state.order.previewsBackground,
    isBackgroundFromOrderFormat: state.order.isBackgroundFromOrderFormat,
  }))

  const orderActions = useStoreActions((actions) => ({
    getImages: actions.order.getImages,
    getGallery: actions.order.getGallery,
    getLinks: actions.order.getLinks,
    resetLinks: actions.order.resetLinks,
    resetUpdatedImages: actions.order.resetUpdatedImages,
    setSelectedImages: actions.order.setSelectedImages,
    resetImageDetails: actions.order.resetImageDetails,
    setImagesToBeDeleted: actions.order.setImagesToBeDeleted,
    setAllSelectedLayers: actions.order.setAllSelectedLayers,
    setOrderRedo: actions.order.setOrderRedo,
    setGalleryBackground: actions.order.setGalleryBackground,
    setPreviewsBackground: actions.order.setPreviewsBackground,
    setIsBackgroundFromOrderFormat: actions.order.setIsBackgroundFromOrderFormat,
  }))

  const location = useLocation()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [isOpenedFullscreenHash, setIsOpenedFullscreenHash] = useState(false)
  const [showScrollToTop, setShowScrollToTop] = useState(false)

  const linksRef = useRef(orderState.links)
  const imagesRef = useRef(orderState.images)
  const galleryContentRef = useRef()
  const imagesWrapRef = useRef()
  const selectablesRef = useRef()
  const isSelecting = useRef(false)
  const startX = useRef()
  const startY = useRef()
  const dragSelect = useRef()
  const selectedImagesRef = useRef(orderState.selectedImages)
  const isOverlayOpenedRef = useRef(layoutState.isOverlayOpened)
  const isDialogModalOpenedRef = useRef(layoutState.isDialogModalOpened)
  const isExplodeOrderModalOpenedRef = useRef(layoutState.isExplodeOrderModalOpened)
  const isDeleteErrorsModalOpenedRef = useRef(layoutState.isDeleteErrorsModalOpened)
  const isUpdatePricesModalOpenedRef = useRef(layoutState.isUpdatePricesModalOpened)
  const isRedoModalOpenedRef = useRef(layoutState.isRedoModalOpened)
  const refreshGalleryInterval = useRef()
  const isBackgroundUpdated = useRef(false)

  const intersectionOptions = {
    root: galleryContentRef.current,
    rootMargin: INTERSECTION_OBSERVER_MARGIN, // Load images when INTERSECTION_OBSERVER_MARGIN (px) away from entering viewport
  }

  const handleLayoutScroll = (e) => {
    if (e.currentTarget.scrollTop) {
      // ok, do nothing
    } else {
      // ok, do nothing
    }
  }

  const initGallery = async () => {
    if (Object.keys(userState.user).length === 0
      || !orderState.orderId
      || !orderState.imageType
      || !orderState.imageSize
      || !orderState.imageSort) return

    const imgElements = document.querySelectorAll('.image-preview')
    imgElements.forEach((imgElement) => {
      removeClass(imgElement, LOADED_CLASS)
    })

    const gallery = await orderActions.getGallery({
      is_admin: userState.user.is_admin,
      ...orderState,
    })

    orderActions.getImages({
      is_admin: userState.user.is_admin,
      ...orderState,
      nameSearch: '',
      page: 1,
      limit: IMAGES_PER_PAGE,
    })

    // eslint-disable-next-line no-nested-ternary
    const totalPages = orderState.imageType === IMAGE_TYPES.input
      ? Math.ceil(gallery.input_count / IMAGES_PER_PAGE)
      // eslint-disable-next-line no-nested-ternary
      : orderState.imageType === IMAGE_TYPES.output
        ? Math.ceil(gallery.output_count / IMAGES_PER_PAGE)
        : orderState.imageType === IMAGE_TYPES.compare
          ? Math.ceil(gallery.compare_count / IMAGES_PER_PAGE)
          : 2

    for (let page = 2; page <= totalPages; page += 1) {
      setTimeout(() => {
        orderActions.getImages({
          is_admin: userState.user.is_admin,
          ...orderState,
          nameSearch: '',
          page,
          limit: IMAGES_PER_PAGE,
        })
      }, (page - 1) * IMAGES_PER_PAGE_TIMEOUT)
    }
  }

  const refreshGallery = async () => {
    if (Object.keys(userState.user).length === 0
      || !orderState.orderId
      || !orderState.imageType
      || !orderState.imageSize
      || !orderState.imageSort) return

    setIsLoading(true)

    orderActions.getGallery({
      is_admin: userState.user.is_admin,
      ...orderState,
    })

    setTimeout(() => {
      orderActions.getImages({
        is_admin: userState.user.is_admin,
        ...orderState,
        nameSearch: '',
      })
    }, 100)
  }

  const resetInterval = () => {
    clearInterval(refreshGalleryInterval.current)
    refreshGalleryInterval.current = setInterval(refreshGallery, INTERVAL_60_SECONDS)
  }

  const getLinks = (linksToGet = []) => {
    if (linksToGet.length) {
      orderActions.getLinks({
        image_ids: [
          ...linksToGet.map((image) => image?.id),
        ],
        s3_paths: [
          ...linksToGet.map((image) => image?.image_resource[image.id].s3_path),
        ],
        original_s3_paths: [
          ...linksToGet.map((image) => image?.image_resource[image.id].original_s3_path),
        ],
        formats: [
          ...linksToGet.map((image) => image?.image_resource[image.id].format),
        ],
        thumbnail_types: [
          ...linksToGet.map((image) => image?.image_resource[image.id].thumbnail_type),
        ],
        image_sizes: [
          ...Array(linksToGet.length).fill(orderState.imageSize || IMAGE_SIZES.small),
        ],
      })
      orderActions.resetUpdatedImages()
    }
  }

  const observer = new IntersectionObserver((entries, ob) => {
    const images = []
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        images.push(imagesRef.current.find((image) => image.id === parseInt(entry.target.dataset.id, 10)))
        ob.unobserve(entry.target) // Stop observing once the image is loaded
      }
    })
    getLinks(images)
  }, intersectionOptions)

  const observeNewElements = () => {
    const targets = document.querySelectorAll(`.image-preview:not(.${LOADED_CLASS})`)
    targets.forEach((target) => {
      observer.observe(target)
      addClass(target, LOADED_CLASS) // Mark it as loaded to avoid re-observing it
    })
  }

  const preventPinchToZoom = (e) => {
    if (!isOverlayOpenedRef.current) return

    if (e.ctrlKey) {
      e.preventDefault()
    }
  }

  useEffect(() => {
    if (layoutState.isOverlayOpened) {
      window.addEventListener('wheel', preventPinchToZoom, { passive: false })
    } else {
      window.removeEventListener('wheel', preventPinchToZoom, { passive: false })
    }

    isOverlayOpenedRef.current = layoutState.isOverlayOpened

    // cleanup: "remove event listeners" and "intervals" to avoid memory leaks by creating the same listeners
    return () => {
      window.removeEventListener('wheel', preventPinchToZoom, { passive: false })
    }
  }, [layoutState.isOverlayOpened])

  const closeModalOnEsc = (e) => {
    if (e.key === 'Escape') {
      layoutActions.updateDialogModalOpened(false)
      layoutActions.updateExplodeOrderModalOpened(false)
      layoutActions.updateDeleteErrorsModalOpened(false)
      layoutActions.updateUpdatePricesModalOpened(false)
      layoutActions.updateRedoModalOpened(false)
    }
  }

  useEffect(() => {
    isDialogModalOpenedRef.current = layoutState.isDialogModalOpened
    if (!layoutState.isDialogModalOpened) {
      orderActions.setImagesToBeDeleted([])
      document.removeEventListener('keydown', closeModalOnEsc)
    } else {
      document.addEventListener('keydown', closeModalOnEsc)
    }
  }, [layoutState.isDialogModalOpened])

  useEffect(() => {
    selectedImagesRef.current = orderState.selectedImages
  }, [orderState.selectedImages])

  useEffect(() => {
    isExplodeOrderModalOpenedRef.current = layoutState.isExplodeOrderModalOpened
    isDeleteErrorsModalOpenedRef.current = layoutState.isDeleteErrorsModalOpened
    isUpdatePricesModalOpenedRef.current = layoutState.isUpdatePricesModalOpened
    isRedoModalOpenedRef.current = layoutState.isRedoModalOpened
    if (
      layoutState.isExplodeOrderModalOpened
      || layoutState.isUpdatePricesModalOpened
      || layoutState.isDeleteErrorsModalOpened
      || layoutState.isRedoModalOpened
    ) {
      document.addEventListener('keydown', closeModalOnEsc)
    } else {
      document.removeEventListener('keydown', closeModalOnEsc)
    }
  }, [
    layoutState.isExplodeOrderModalOpened,
    layoutState.isUpdatePricesModalOpened,
    layoutState.isDeleteErrorsModalOpened,
    layoutState.isRedoModalOpened,
  ])

  useEffect(() => {
    if (Object.keys(userState.user).length === 0
      || !orderState.orderId
      || !orderState.imageType
      || !orderState.imageSize
      || !orderState.imageSort) return () => { }

    initGallery().then(() => { })

    galleryContentRef.current.scrollTo({ behavior: 'smooth', top: 0 })

    // "add intervals"
    refreshGalleryInterval.current = setInterval(refreshGallery, INTERVAL_60_SECONDS)

    // cleanup: "remove intervals" to avoid memory leaks by creating the same listeners
    return () => {
      clearInterval(refreshGalleryInterval.current)
    }
  }, [
    userState.user.is_admin,
    orderState.orderId,
    orderState.imageType,
    orderState.imageSize,
    orderState.imageSort,
    // orderState.imageFilter, // will be not be used for now, filtering only on FE
    orderState.isCombineExplode,
  ])

  useEffect(() => {
    if (orderState.updatedImages?.length) {
      getLinks(orderState.updatedImages)
      orderActions.resetImageDetails()
    }
  }, [orderState.updatedImages])

  useEffect(() => {
    if (linksRef.current !== orderState.links) {
      linksRef.current = orderState.links
    }
  }, [orderState.links])

  useEffect(() => {
    if (!orderState.images) return

    if (imagesRef.current !== orderState.images) {
      imagesRef.current = orderState.images
    }

    setTimeout(() => {
      observeNewElements()
    }, 100) // To make sure that the elements are created after getImages response
  }, [orderState.images])

  const handleKeyDown = (e) => {
    if (
      e.key === 'A'
      && e.shiftKey
      && orderState.filteredImages.length !== selectedImagesRef.current.length
      && !isOverlayOpenedRef.current
      && !isDialogModalOpenedRef.current
      && !isExplodeOrderModalOpenedRef.current
      && !isDeleteErrorsModalOpenedRef.current
      && !isUpdatePricesModalOpenedRef.current
    ) {
      orderActions.setSelectedImages(orderState.filteredImages.map((image) => image.id))
    } else if (
      e.key === 'A'
      && e.shiftKey
      && orderState.filteredImages.length === selectedImagesRef.current.length
      && !isOverlayOpenedRef.current
      && !isDialogModalOpenedRef.current
      && !isExplodeOrderModalOpenedRef.current
      && !isDeleteErrorsModalOpenedRef.current
      && !isUpdatePricesModalOpenedRef.current
    ) {
      orderActions.setSelectedImages([])
    }
  }

  const checkRectIntersection = (rect, boxRect) => (rect.left < boxRect.right
    && rect.right > boxRect.left
    && rect.top < boxRect.bottom
    && rect.bottom > boxRect.top)

  const onMouseDown = (e) => {
    if (!e.shiftKey) return // Only start selecting if Shift key is pressed
    if (!isAllImagesLoaded(
      orderState.imageType,
      orderState.images.length,
      orderState.gallery?.input_count,
      orderState.gallery?.output_count,
      orderState.gallery?.compare_count,
    )) return

    isSelecting.current = true

    selectablesRef.current = document.querySelectorAll('.selectable')

    const rect = galleryContentRef.current.getBoundingClientRect()

    startX.current = e.clientX
    startY.current = e.clientY - rect.top + galleryContentRef.current.scrollTop

    // Create selection box
    dragSelect.current = document.createElement('div')
    dragSelect.current.style.position = 'absolute'
    dragSelect.current.className = DRAG_SELECT_CLASS
    dragSelect.current.style.left = `${startX.current}px`
    dragSelect.current.style.top = `${startY.current}px`
    imagesWrapRef.current.appendChild(dragSelect.current)

    // Prevent text selection
    addClass(document.body, NOSELECT_CLASS)
  }

  const onMouseMove = (e) => {
    if (!isSelecting.current) return

    const rect = galleryContentRef.current.getBoundingClientRect()

    const currentX = e.clientX
    const currentY = e.clientY - rect.top + galleryContentRef.current.scrollTop

    // Calculate the dimensions of the selection box
    const width = currentX - startX.current
    const height = currentY - startY.current

    dragSelect.current.style.width = `${Math.abs(width)}px`
    dragSelect.current.style.height = `${Math.abs(height)}px`
    dragSelect.current.style.left = `${(width < 0 ? currentX : startX.current)}px`
    dragSelect.current.style.top = `${(height < 0 ? currentY : (startY.current))}px`
  }

  const onMouseUp = () => {
    if (!isSelecting.current) return
    isSelecting.current = false

    const rect = dragSelect.current.getBoundingClientRect()
    const ids = [...selectedImagesRef.current]
    selectablesRef.current.forEach((selectable) => {
      const boxRect = selectable.getBoundingClientRect()
      let deSelect = false
      if (checkRectIntersection(rect, boxRect) && ids.includes(parseInt(selectable.dataset.imageId, 10))) {
        ids.splice(ids.indexOf(parseInt(selectable.dataset.imageId, 10)), 1)
        deSelect = true
      }
      if (checkRectIntersection(rect, boxRect) && !deSelect) {
        ids.push(parseInt(selectable.dataset.imageId, 10))
      }
    })

    orderActions.setSelectedImages(ids)

    // Remove the selection box
    imagesWrapRef.current.removeChild(dragSelect.current)

    // Allow text selection again
    removeClass(document.body, NOSELECT_CLASS)
  }

  const onApproveRedo = () => {
    orderActions.setOrderRedo({
      is_admin: userState.user.is_admin,
      body: {
        order_id: orderState.orderId,
      },
    }).then(() => {
      layoutActions.updateRedoModalOpened(false)
      refreshGallery().then(() => { })
    })
  }

  const openFullscreenHash = () => {
    const imageMatch = location.hash.substring(1).match(/\d+$/)
    const imageId = imageMatch ? parseInt(imageMatch[0], 10) : null
    if (imageId) {
      const imageElement = document.getElementById(`image-${imageId}`)
      if (imageElement) {
        imageElement.click()
        setIsOpenedFullscreenHash(true)
        // history.replaceState(null, null, ' ') // remove hash if needed future
      }
    }
  }

  useEffect(() => {
    if (!orderState.filteredImages) return () => { }

    galleryContentRef.current.addEventListener('mousedown', onMouseDown)
    galleryContentRef.current.addEventListener('mousemove', onMouseMove)
    galleryContentRef.current.addEventListener('mouseup', onMouseUp)
    document.addEventListener('keydown', handleKeyDown)

    setIsLoading(false)

    if (location.hash && !isOpenedFullscreenHash) {
      openFullscreenHash()
    }

    // cleanup: "remove event listeners" and "intervals" to avoid memory leaks by creating the same listeners
    return () => {
      galleryContentRef.current.removeEventListener('mousedown', onMouseDown)
      galleryContentRef.current.removeEventListener('mousemove', onMouseMove)
      galleryContentRef.current.removeEventListener('mouseup', onMouseUp)
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [orderState.filteredImages])

  useEffect(() => {
    orderActions.resetLinks()
  }, [orderState.orderId, orderState.imageType, orderState.imageSize])

  useEffect(() => {
    orderActions.setSelectedImages([])
  }, [orderState.imageType])

  useEffect(() => {
    if (Object.keys(userState.user).length === 0) return // do, nothing if role is not set yet

    if (userState.user.is_admin) {
      if (location.pathname.startsWith(CLIENT_GALLERY_PREFIX)) {
        navigate(getGalleryUrl(
          userState.user.is_admin,
          orderState.imageType,
          orderState.orderId,
          orderState.imageSize,
          orderState.imageSort,
        ))
      }
    } else if (location.pathname.startsWith(ADMIN_GALLERY_PREFIX)) { // all other roles
      navigate(getGalleryUrl(
        userState.user.is_admin,
        orderState.imageType,
        orderState.orderId,
        orderState.imageSize,
        orderState.imageSort,
      ))
    }
  }, [userState.user])

  useEffect(() => {
    if (isBackgroundUpdated.current) return
    if (!orderState.gallery?.gallery_backgrounds) return

    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < orderState.gallery?.gallery_backgrounds.length; index++) {
      const bg = orderState.gallery.gallery_backgrounds[index]
      if (bg.is_format_background && bg.color !== TRANSPARENT_KEY) {
        orderActions.setGalleryBackground(bg.color)
        orderActions.setIsBackgroundFromOrderFormat(true)
        isBackgroundUpdated.current = true
        Cookie.setCookie(`gallery_bg_${orderState.orderId}`, bg.color)
        break
      }
    }
  }, [orderState.gallery?.gallery_backgrounds])

  useEventListener('scroll', (e) => {
    if (e.target === galleryContentRef.current) {
      const { scrollTop } = galleryContentRef.current
      setShowScrollToTop(scrollTop > 800)
    }
  }, galleryContentRef.current)

  return (
    <>
      <div
        onScroll={handleLayoutScroll}
        ref={galleryContentRef}
        className="gallery-content scrollbar-overflow scrollbar-overflow__light"
        style={{ backgroundColor: orderState.galleryBackground }}
      >
        <div className="images--wrap" ref={imagesWrapRef}>
          <Previews refreshGallery={refreshGallery} />
        </div>

        {showScrollToTop && (
          <button
            type="button"
            aria-label="Scroll to top"
            className="scroll-to-top"
            onClick={() => galleryContentRef.current.scrollTo({ behavior: 'smooth', top: 0 })}
          >
            <ArrowUpwardSvg />
          </button>
        )}
      </div>

      {orderState.selectedImages.length > 0 && (
        <Actions refreshGallery={refreshGallery} resetInterval={resetInterval} />
      )}

      {layoutState.isInfosOpened && (
        <InfoBar />
      )}

      {layoutState.isOverlayOpened && (
        <Overlay refreshGallery={refreshGallery} resetInterval={resetInterval} />
      )}

      {Object.keys(orderState.downloadUrls).length > 0 && (
        <Download urls={orderState.downloadUrls} />
      )}

      {layoutState.isDialogModalOpened && (
        <Dialogs refreshGallery={refreshGallery} resetInterval={resetInterval} />
      )}

      {layoutState.isDeleteErrorsModalOpened && (
        <DeleteErrorsDialog refreshGallery={refreshGallery} resetInterval={resetInterval} />
      )}

      {layoutState.isExplodeOrderModalOpened && (
        <ExplodeOrderDialog refreshGallery={refreshGallery} resetInterval={resetInterval} />
      )}

      {layoutState.isUpdatePricesModalOpened && (
        <UpdatePricesDialog refreshGallery={refreshGallery} resetInterval={resetInterval} />
      )}

      {layoutState.isRedoModalOpened && (
        <Dialog
          isShown
          onClickCloseIcon={() => layoutActions.updateRedoModalOpened(false)}
          onClickOutside={() => layoutActions.updateRedoModalOpened(false)}
          isCentered
          onClickCancel={() => layoutActions.updateRedoModalOpened(false)}
          title={Translation.set_order_status_to_redo}
          yesLabel={Translation.yes}
          cancelLabel={Translation.cancel}
          onClickYes={onApproveRedo}
          content={null}
        />
      )}

      <Loader isLoading={isLoading} />
    </>
  )
}

export default Gallery
