import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { COMMENT_STATUSES, COMMENT_TYPES, IMAGE_SIZES } from '../../../../helpers/Constants'
import { Translation } from '../../../../helpers/Translation'
import { API_ENDPOINTS_GALLERY } from '../../../../helpers/Urls'
import {
  appendShapes,
  getEndpointUrl,
  getIsExpanded,
  getStrokeWidth,
  prepareComment,
} from '../../../../helpers/Utils'

import { ReactComponent as CommentedDrawingFreehandSvg } from '../../../../svg/commented_drawing_freehand.svg'
import { ReactComponent as CommentedTextSvg } from '../../../../svg/commented_text.svg'
import { ReactComponent as CrossSvg } from '../../../../svg/action_cross.svg'
import { ReactComponent as CommentRejectedSvg } from '../../../../svg/comment_rejected.svg'

import './index.scss'

const CommentOverlay = ({
  comment,
  overlayImgDrawingRef,
  refreshGallery,
  resetInterval,
}) => {
  const userState = useStoreState((state) => ({
    user: state.user.user,
  }))

  const orderState = useStoreState((state) => ({
    imageShapes: state.order.imageShapes,
    shapesJson: state.order.shapesJson,
    selectedImages: state.order.selectedImages,
    orderId: state.order.orderId,
    imageType: state.order.imageType,
  }))

  const orderActions = useStoreActions((actions) => ({
    imageAction: actions.order.imageAction,
    getImageDetails: actions.order.getImageDetails,
  }))

  const [isExpanded] = useState(getIsExpanded())

  const onMouseEnter = () => {
    if (comment.shapes && comment.shapes.length) {
      // eslint-disable-next-line no-param-reassign
      overlayImgDrawingRef.current.innerHTML = ''

      const strokeWidth = getStrokeWidth(
        overlayImgDrawingRef.current.getAttribute('width'),
        overlayImgDrawingRef.current.getAttribute('height'),
      )
      appendShapes(comment.shapes, strokeWidth, overlayImgDrawingRef.current)
    }
  }

  const onMouseLeave = () => {
    const strokeWidth = getStrokeWidth(
      overlayImgDrawingRef.current.getAttribute('width'),
      overlayImgDrawingRef.current.getAttribute('height'),
    )
    appendShapes(orderState.imageShapes, strokeWidth, overlayImgDrawingRef.current)
    appendShapes(orderState.shapesJson, strokeWidth, overlayImgDrawingRef.current)
  }

  const handleCommentDelete = async (e) => {
    const commentEl = e.currentTarget?.parentNode
    const res = await orderActions.imageAction({
      url: getEndpointUrl(userState.user.is_admin, API_ENDPOINTS_GALLERY.commentDelete),
      body: {
        comment_ids: [parseInt(comment.id, 10)],
      },
    })

    if (res.success) {
      if (commentEl) commentEl.style.display = 'none' // hide deleted comment
      refreshGallery()
      resetInterval()

      // update image history
      orderActions.getImageDetails({
        image_ids: [parseInt(comment.image_id, 10)],
        order_ids: [orderState.orderId],
        image_type: orderState.imageType,
        image_size: IMAGE_SIZES.extraLarge,
        is_with_comments: isExpanded.history,
      })
    }
  }

  return (
    <div
      className={`overlay--comment__wrap ${parseInt(comment.status, 10) === COMMENT_STATUSES.rejected
        ? 'overlay--comment__wrap--deleted' : ''}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="overlay--comment__icon overlay--comment__type">
        {comment.shapes
          ? <CommentedDrawingFreehandSvg stroke={comment.colour} />
          : <CommentedTextSvg className="right-2" />}
      </div>

      {comment.type === COMMENT_TYPES.qaClient && (
        <div className={`overlay--comment__icon overlay--comment__icon--client 
          ${parseInt(comment.status, 10) === COMMENT_STATUSES.active
          ? 'overlay--comment__icon--client__approved' : ''}`}
        >
          {Translation.client_short}
        </div>
      )}

      {comment.type === COMMENT_TYPES.clientQa && (
        <div className="overlay--comment__icon overlay--comment__icon--rejected">
          <CommentRejectedSvg />
        </div>
      )}

      <div className="overlay--comment">
        {prepareComment(comment.comment)}
      </div>

      <div className="overlay--comment__delete" onClick={handleCommentDelete}>
        <CrossSvg />
      </div>
    </div>
  )
}

CommentOverlay.propTypes = {
  comment: PropTypes.instanceOf(Object),
  overlayImgDrawingRef: PropTypes.instanceOf(Object),
  refreshGallery: PropTypes.func,
  resetInterval: PropTypes.func,
}

CommentOverlay.defaultProps = {
  comment: {},
  overlayImgDrawingRef: {},
  refreshGallery: () => {
  },
  resetInterval: () => {
  },
}

export default CommentOverlay
