import React from 'react'
import PropTypes from 'prop-types'

import { IMAGE_ERROR_CLASS, IMAGE_ERROR_CODES } from '../../../../../helpers/Constants'
import { Translation } from '../../../../../helpers/Translation'

const ImageColorSpace = ({
  isOriginalImage,
  originalImageDetails,
  image,
  removeErrorCode,
}) => {
  const hasError = isOriginalImage ? originalImageDetails?.color_space_error : image.color_space_error

  return (
    <div className={hasError ? 'info info-error' : 'info'}>
      <span
        className={hasError ? IMAGE_ERROR_CLASS : ''}
        onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.colorSpaceError)}
      >
        {isOriginalImage
          ? (originalImageDetails?.colour_space || Translation.no_colour_space)
          : (image.colour_space || Translation.no_colour_space)}
      </span>
    </div>
  )
}

ImageColorSpace.propTypes = {
  isOriginalImage: PropTypes.bool.isRequired,
  originalImageDetails: PropTypes.shape({
    color_space_error: PropTypes.bool,
    colour_space: PropTypes.string,
  }),
  image: PropTypes.shape({
    color_space_error: PropTypes.bool,
    colour_space: PropTypes.string,
  }).isRequired,
  removeErrorCode: PropTypes.func.isRequired,
}

ImageColorSpace.defaultProps = {
  originalImageDetails: {
    color_space_error: false,
    colour_space: '',
  },
}

export default ImageColorSpace
