import React, { useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { Translation } from '../../../../helpers/Translation'
import { PREVIEW_BG_COLORS, TRANSPARENT_KEY } from '../../../../helpers/Constants'

import ColorPicker from '../../../../components/ColorPicker'

import { ReactComponent as OverlayBackgroundTransparentSvg } from '../../../../svg/overlay_background_transparent.svg'

import './index.scss'

const Background = () => {
  const orderState = useStoreState((state) => ({
    gallery: state.order.gallery,
    galleryBackground: state.order.galleryBackground,
    previewsBackground: state.order.previewsBackground,
  }))

  const orderActions = useStoreActions((actions) => ({
    setGalleryBackground: actions.order.setGalleryBackground,
    setPreviewsBackground: actions.order.setPreviewsBackground,
  }))

  const [isColorPickerOpened, setIsColorPickerOpened] = useState({
    galleryBackground: false,
    previewsBackground: false,
  })

  const closePicker = () => {
    setIsColorPickerOpened({
      galleryBackground: false,
      previewsBackground: false,
    })
  }

  const handleGalleryBGColorSelect = (color) => {
    orderActions.setGalleryBackground(color)
    closePicker()
  }

  const handlePreviewsBGColorSelect = (color) => {
    orderActions.setPreviewsBackground(color)
    closePicker()
  }

  return (
    <div className="background-block-container">
      <div className="background-type-container">
        <div
          className="color"
          style={{ backgroundColor: orderState.galleryBackground }}
          onClick={() => setIsColorPickerOpened({ ...isColorPickerOpened, galleryBackground: true })}
        >
          {isColorPickerOpened.galleryBackground && (
            <ColorPicker
              onColorChange={handleGalleryBGColorSelect}
              closePicker={closePicker}
              colors={orderState.gallery?.gallery_backgrounds
                .map((bg) => bg.color) || []}
              labels={orderState.gallery?.gallery_backgrounds
                .filter((bg) => bg.is_format_background)
                .map((bg, index) => `F${index + 1}`) || []}
              tooltips={orderState.gallery?.gallery_backgrounds
                .map((bg) => bg.name) || []}
              isTransparentDisabled
              customColorType="pickerColorBg"
            />
          )}
        </div>
        <span className="gallery-background">
          {Translation.gallery_background}
        </span>
      </div>

      <div className="background-type-container">
        <div
          className="color"
          style={{ backgroundColor: orderState.previewsBackground }}
          onClick={() => setIsColorPickerOpened({ ...isColorPickerOpened, previewsBackground: true })}
        >
          {orderState.previewsBackground === TRANSPARENT_KEY && <OverlayBackgroundTransparentSvg />}
          {isColorPickerOpened.previewsBackground && (
            <ColorPicker
              onColorChange={handlePreviewsBGColorSelect}
              closePicker={closePicker}
              colors={PREVIEW_BG_COLORS}
              customColorType="pickerColorBg"
            />
          )}
        </div>
        <span className="gallery-background">
          {Translation.image_background}
        </span>
      </div>
    </div>
  )
}

export default Background
