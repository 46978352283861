import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@melloware/coloris/dist/coloris.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import Coloris from '@melloware/coloris'

import { PX_TO_REM, TRANSPARENT_KEY } from '../../helpers/Constants'
import { Translation } from '../../helpers/Translation'
import { getPickerColor } from '../../helpers/Utils'

import Select from '../Select'
import Tooltip from '../Tooltip'

import { ReactComponent as OverlayBackgroundTransparentSvg } from '../../svg/overlay_background_transparent.svg'
import { ReactComponent as OverlayBackgroundAddSvg } from '../../svg/overlay_background_add.svg'
import { ReactComponent as PencilSvg } from '../../svg/pencil.svg'

import './index.scss'

const COLORS = [
  '#000000',
  '#D00B1C',
  '#3D96F7',
  '#000000',
  '#3B3B3B',
  '#777777',
  '#B9B9B9',
  '#FFFFFF',
  '#F8F3ED',
  '#F1F1F1',
]

const hexToRGB = (hex) => {
  // Ensure the input is a valid hex color
  if (!/^#([A-Fa-f0-9]{3}|[A-Fa-f0-9]{6})$/.test(hex)) {
    return { r: '255', g: '255', b: '255' }
  }

  let normalizedHex = hex.slice(1)
  if (normalizedHex.length === 3) {
    normalizedHex = normalizedHex
      .split('')
      .map((char) => char + char)
      .join('')
  }

  const r = parseInt(normalizedHex.slice(0, 2), 16)
  const g = parseInt(normalizedHex.slice(2, 4), 16)
  const b = parseInt(normalizedHex.slice(4, 6), 16)

  return { r, g, b }
}

const RGBToHex = (r, g, b) => {
  const toHex = (c) => {
    const hex = c.toString(16)
    return hex.length === 1 ? `0${hex}` : hex
  }

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`
}

const ColorPicker = ({
  onColorChange,
  closePicker,
  colors,
  labels,
  tooltips,
  customColorType,
  isTransparentDisabled,
}) => {
  const colorisRef = useRef(null)
  const colorPickerRef = useRef()
  const [isAddingNewColor, setIsAddingNewColor] = useState(false)
  const [colorType, setColorType] = useState('hex')
  const initPickerColor = getPickerColor(customColorType)
  const [pickedColor, setPickedColor] = useState(initPickerColor)
  const [RGBCode, setRGBCode] = useState(hexToRGB(initPickerColor))
  const [hexInputValue, setHexInputValue] = useState(initPickerColor)

  const handleClickOutside = (event) => {
    if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
      closePicker()
    }
  }

  const handleColorSelect = (color) => {
    if (color === TRANSPARENT_KEY && isTransparentDisabled) return

    onColorChange(color)
    setTimeout(() => {
      closePicker()
    }, 100)
  }

  const handleAddNewColor = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setIsAddingNewColor(true)
  }

  const handlePickerColorChange = (color) => {
    onColorChange(color)
    setPickedColor(color)
    localStorage.setItem(customColorType, color)
  }

  const handleOpenPicker = () => {
    Coloris({
      el: `#${colorisRef.current.id}`,
      wrap: false,
      alpha: false,
      focusInput: false,
      defaultColor: pickedColor,
      onChange: handlePickerColorChange,
    })
    colorisRef.current.click()
  }

  const handleHexCodeChange = (e) => {
    const val = e.currentTarget.value
    setHexInputValue(val)
    if (/^#(([0-9A-Fa-f]{2}){3,4}|[0-9A-Fa-f]{3})$/.test(val)) {
      handlePickerColorChange(val)
      setRGBCode(hexToRGB(e.currentTarget.value))
    }
  }

  const handleRGBCodeChange = (e, rgb) => {
    const newVal = {
      ...RGBCode,
      [rgb]: parseInt(e.currentTarget.value, 10),
    }

    setRGBCode(newVal)
    setHexInputValue(RGBToHex(newVal.r, newVal.g, newVal.b))

    const val = `rgb(${newVal.r},${newVal.g},${newVal.b})`
    // eslint-disable-next-line max-len
    if (/^rgb[(](?:\s*0*(?:\d\d?(?:\.\d+)?(?:\s*%)?|\.\d+\s*%|100(?:\.0*)?\s*%|(?:1\d\d|2[0-4]\d|25[0-5])(?:\.\d+)?)\s*(?:,(?![)])|(?=[)]))){3}[)]$/.test(val)) {
      handlePickerColorChange(RGBToHex(newVal.r, newVal.g, newVal.b))
    }
  }

  useEffect(() => {
    Coloris.init()

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div ref={colorPickerRef} className="color-picker">
      <div className="top">
        {(colors || COLORS).map((color, index) => (tooltips[index] ? (
          <Tooltip
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            text={tooltips[index]}
            position="right"
            offset={`${PX_TO_REM['30']}rem`}
          >
            <div
              className="color"
              onClick={() => handleColorSelect(color)}
              style={{ backgroundColor: color }}
            >
              {color === TRANSPARENT_KEY && <OverlayBackgroundTransparentSvg />}
              {labels[index]}
            </div>
          </Tooltip>
        ) : (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className="color"
            onClick={() => handleColorSelect(color)}
            style={{ backgroundColor: color }}
          >
            {color === TRANSPARENT_KEY && <OverlayBackgroundTransparentSvg />}
            {labels[index]}
          </div>
        )))}
        {customColorType && (
          pickedColor ? (
            <div
              className="color"
              style={{ backgroundColor: pickedColor }}
              onClick={() => handleColorSelect(pickedColor)}
            >
              <div className="pencil" onClick={handleAddNewColor}>
                <Tooltip
                  text={Translation.select_custom_color}
                  position="right"
                  offset={`${PX_TO_REM['22']}rem`}
                >
                  <PencilSvg />
                </Tooltip>
              </div>
            </div>
          ) : (
            <OverlayBackgroundAddSvg onClick={handleAddNewColor} />
          )
        )}
      </div>

      {isAddingNewColor && (
        <div className="bottom">
          <Select
            options={[
              {
                value: 'hex',
                label: 'HEX',
              },
              {
                value: 'rbg',
                label: 'RGB',
              },
            ]}
            onChange={(e) => setColorType(e.target.value)}
            value={colorType}
          />
          {pickedColor ? (
            <div className="color" style={{ backgroundColor: pickedColor }} onClick={handleOpenPicker} />
          ) : (
            <OverlayBackgroundTransparentSvg onClick={handleOpenPicker} />
          )}
          {colorType === 'hex' ? (
            <input
              type="text"
              value={hexInputValue}
              placeholder="#FFFFFF"
              onChange={handleHexCodeChange}
              className="color-code-input hex"
            />
          ) : (
            <>
              <input
                type="number"
                value={RGBCode.r}
                placeholder="23"
                onChange={(e) => handleRGBCodeChange(e, 'r')}
                className="color-code-input rgb"
              />
              <input
                type="number"
                value={RGBCode.g}
                placeholder="23"
                onChange={(e) => handleRGBCodeChange(e, 'g')}
                className="color-code-input rgb"
              />
              <input
                type="number"
                value={RGBCode.b}
                placeholder="23"
                onChange={(e) => handleRGBCodeChange(e, 'b')}
                className="color-code-input rgb"
              />
            </>
          )}
          <input
            id="coloris"
            ref={colorisRef}
            type="text"
            className="coloris-input"
            style={{ top: `-${PX_TO_REM['14']}rem` }}
            value={pickedColor}
            onChange={() => {
            }}
          />
        </div>
      )}
    </div>
  )
}

ColorPicker.propTypes = {
  onColorChange: PropTypes.func,
  closePicker: PropTypes.func,
  colors: PropTypes.arrayOf(PropTypes.string),
  labels: PropTypes.arrayOf(PropTypes.string),
  tooltips: PropTypes.arrayOf(PropTypes.string),
  customColorType: PropTypes.string,
  isTransparentDisabled: PropTypes.bool,
}

ColorPicker.defaultProps = {
  onColorChange: () => {
  },
  closePicker: () => {
  },
  colors: [],
  labels: [],
  tooltips: [],
  customColorType: null,
  isTransparentDisabled: false,
}

export default ColorPicker
