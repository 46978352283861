import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import PropTypes from 'prop-types'

import { Translation } from '../../../../helpers/Translation'
import { isAltKey, removeErrorClasses } from '../../../../helpers/Utils'

import Typography from '../../../../components/Typography'

import ImageFileName from '../ImageInfos/ImageFileName'
import ImageRatio from '../ImageInfos/ImageRatio'
import ImageDimensions from '../ImageInfos/ImageDimensions'
import ImageColorSpace from '../ImageInfos/ImageColorSpace'
import ImageBackgroundColor from '../ImageInfos/ImageBackgroundColor'
import ImageColorDepth from '../ImageInfos/ImageColorDepth'

import { ReactComponent as ExpandSvg } from '../../../../svg/expand.svg'

import './index.scss'

const CollapsibleImageInfo = ({
  image,
  refreshGallery,
  resetInterval,
  isOriginalImage,
  originalImageDetails,
}) => {
  const userState = useStoreState((state) => ({
    user: state.user.user,
  }))

  const orderActions = useStoreActions((actions) => ({
    removeError: actions.order.removeError,
  }))

  const [isCollapsed, setIsCollapsed] = useState(JSON.parse(localStorage.getItem('isImageInfoCollapsed')) || false)

  useEffect(() => {
    localStorage.setItem('isImageInfoCollapsed', isCollapsed)
  }, [isCollapsed])

  const removeErrorCode = async (e, errorCode) => {
    if (!userState.user.is_admin) return

    if (isAltKey(e)) {
      e.stopPropagation()

      removeErrorClasses(image, e.currentTarget, errorCode)

      const res = await orderActions.removeError({
        is_admin: userState.user.is_admin,
        body: {
          image_ids: [image.id],
          error_codes: [errorCode],
        },
      })
      if (res) {
        refreshGallery()
        resetInterval()
      }
    }
  }

  return (
    <div className="collapsible-image-info">
      {isCollapsed && (
        <div className="collapsible-image-info__content">
          <div className="image-overlay--left__content">
            <Typography
              label={Translation.system_check}
              font="semibold"
            />

            <Typography
              label={
                `${image.original_image_id
                  ? image.original_image_id
                  : image.id}`
              }
            />

            <ImageFileName
              isOriginalImage={isOriginalImage}
              originalImageDetails={originalImageDetails}
              image={image}
              removeErrorCode={removeErrorCode}
            />

            <ImageRatio
              isOriginalImage={isOriginalImage}
              image={image}
            />

            <ImageDimensions
              isOriginalImage={isOriginalImage}
              originalImageDetails={originalImageDetails}
              image={image}
              removeErrorCode={removeErrorCode}
            />

            <ImageColorSpace
              isOriginalImage={isOriginalImage}
              originalImageDetails={originalImageDetails}
              image={image}
              removeErrorCode={removeErrorCode}
            />

            <ImageBackgroundColor
              isOriginalImage={isOriginalImage}
              originalImageDetails={originalImageDetails}
              image={image}
              removeErrorCode={removeErrorCode}
            />

            <ImageColorDepth
              isOriginalImage={isOriginalImage}
              originalImageDetails={originalImageDetails}
              image={image}
            />
          </div>
        </div>
      )}
      <div className="collapse-toggle" onClick={() => setIsCollapsed((prev) => !prev)}>
        <ExpandSvg style={{ rotate: isCollapsed ? 'none' : '180deg' }} />
      </div>
    </div>
  )
}

CollapsibleImageInfo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  image: PropTypes.object,
  refreshGallery: PropTypes.func,
  resetInterval: PropTypes.func,
  isOriginalImage: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  originalImageDetails: PropTypes.object,
}

CollapsibleImageInfo.defaultProps = {
  image: {},
  refreshGallery: () => {
  },
  resetInterval: () => {
  },
  isOriginalImage: false,
  originalImageDetails: {},
}

export default CollapsibleImageInfo
