import React from 'react'
import PropTypes from 'prop-types'

import { IMAGE_ERROR_CLASS, IMAGE_ERROR_CODES } from '../../../../../helpers/Constants'

const ImageFileName = ({
  isOriginalImage,
  originalImageDetails,
  image,
  removeErrorCode,
}) => {
  const getClassName = (error) => (error ? IMAGE_ERROR_CLASS : '')

  return (
    <div
      className={
        (isOriginalImage
          ? (originalImageDetails?.wrong_file_name || originalImageDetails?.wrong_format)
          : (image.wrong_file_name || image.wrong_format))
          ? 'info info-error'
          : 'info'
      }
    >
      <span
        className={getClassName(isOriginalImage ? originalImageDetails?.wrong_file_name : image.wrong_file_name)}
        onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.wrongFileName)}
      >
        {isOriginalImage ? originalImageDetails?.name_without_format : image.name_without_format}
      </span>
      <span>&nbsp;.&nbsp;</span>
      <span
        className={getClassName(isOriginalImage ? originalImageDetails?.wrong_format : image.wrong_format)}
        onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.wrongFormat)}
      >
        {isOriginalImage ? originalImageDetails?.format : image.format}
      </span>
    </div>
  )
}

ImageFileName.propTypes = {
  isOriginalImage: PropTypes.bool.isRequired,
  originalImageDetails: PropTypes.shape({
    wrong_file_name: PropTypes.bool,
    wrong_format: PropTypes.bool,
    name_without_format: PropTypes.string,
    format: PropTypes.string,
  }),
  image: PropTypes.shape({
    wrong_file_name: PropTypes.bool,
    wrong_format: PropTypes.bool,
    name_without_format: PropTypes.string,
    format: PropTypes.string,
  }).isRequired,
  removeErrorCode: PropTypes.func.isRequired,
}

ImageFileName.defaultProps = {
  originalImageDetails: {
    wrong_file_name: false,
    wrong_format: false,
    name_without_format: '',
    format: '',
  },
}

export default ImageFileName
