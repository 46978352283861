import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { Translation } from '../../../helpers/Translation'
import { API_ENDPOINTS_GALLERY } from '../../../helpers/Urls'
import { DIALOG_NAMES, IMAGE_SIZES } from '../../../helpers/Constants'
import { getEndpointUrl, getIsExpanded, useEventListener } from '../../../helpers/Utils'

import Typography from '../../../components/Typography'
import Dialog from '../../../components/Dialog'

import './index.scss'

const Dialogs = ({ refreshGallery, resetInterval }) => {
  const userState = useStoreState((state) => ({
    user: state.user.user,
  }))

  const layoutState = useStoreState((state) => ({
    isDialogModalOpened: state.layout.isDialogModalOpened,
    dialogModalState: state.layout.dialogModalState,
  }))

  const layoutActions = useStoreActions((actions) => ({
    updateDialogModalOpened: actions.layout.updateDialogModalOpened,
  }))

  const orderState = useStoreState((state) => ({
    imagesToBeDeleted: state.order.imagesToBeDeleted,
    selectedImages: state.order.selectedImages,
    imageToBeAllowedOverwrite: state.order.imageToBeAllowedOverwrite,
    gallery: state.order.gallery,
    orderId: state.order.orderId,
    imageType: state.order.imageType,
  }))

  const orderActions = useStoreActions((actions) => ({
    imageAction: actions.order.imageAction,
    addComment: actions.order.addComment,
    clearImages: actions.order.clearImages,
    setImageToBeAllowedOverwrite: actions.order.setImageToBeAllowedOverwrite,
    getImageDetails: actions.order.getImageDetails,
    updateOrderStatus: actions.order.updateOrderStatus,
  }))

  const [isExpanded] = useState(getIsExpanded())

  const commentTextareaRef = useRef(null)

  const onDeleteImages = async () => {
    if (orderState.imagesToBeDeleted?.length) {
      const res = await orderActions.imageAction({
        url: getEndpointUrl(userState.user.is_admin, API_ENDPOINTS_GALLERY.delete),
        body: {
          image_ids: orderState.imagesToBeDeleted,
        },
      })
      if (res.success) {
        refreshGallery()
        resetInterval()
      }
      layoutActions.updateDialogModalOpened(false)
    }
  }

  const onDeleteComments = async () => {
    if (orderState.selectedImages?.length) {
      const res = await orderActions.imageAction({
        url: getEndpointUrl(userState.user.is_admin, API_ENDPOINTS_GALLERY.commentDelete),
        body: {
          image_ids: orderState.selectedImages,
        },
      })

      if (res.success) {
        refreshGallery()
        resetInterval()

        // update image history
        orderActions.getImageDetails({
          image_ids: orderState.selectedImages,
          order_ids: [orderState.orderId],
          image_type: orderState.imageType,
          image_size: IMAGE_SIZES.extraLarge,
          is_with_comments: isExpanded.history,
        })
      }
      layoutActions.updateDialogModalOpened(false)
    }
  }

  const onApproveComments = async () => {
    if (orderState.selectedImages?.length) {
      const res = await orderActions.imageAction({
        url: getEndpointUrl(userState.user.is_admin, API_ENDPOINTS_GALLERY.commentApprove),
        body: {
          image_ids: orderState.selectedImages,
        },
      })
      if (res.success) {
        refreshGallery()
        resetInterval()
      }
      layoutActions.updateDialogModalOpened(false)
    }
  }

  const onAddComment = async () => {
    if (!orderState.gallery?.is_allowed_to_comment) return
    if (!commentTextareaRef?.current?.value) return

    const res = await orderActions.addComment({
      is_admin: userState.user.is_admin,
      body: {
        image_ids: orderState.selectedImages,
        comment: commentTextareaRef?.current?.value,
        is_reply: false,
      },
    })
    if (res) {
      refreshGallery()
      resetInterval()

      // update image history
      orderActions.getImageDetails({
        image_ids: orderState.selectedImages,
        order_ids: [orderState.orderId],
        image_type: orderState.imageType,
        image_size: IMAGE_SIZES.extraLarge,
        is_with_comments: isExpanded.history,
      })
    }
    layoutActions.updateDialogModalOpened(false)
  }

  const onCommentSubmit = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      onAddComment().then(() => {
      })
    }
  }

  const onClearUploads = async () => {
    const res = await orderActions.clearImages({
      is_admin: userState.user.is_admin,
      body: {
        image_ids: [orderState.imageToBeAllowedOverwrite],
      },
    })
    if (res) {
      refreshGallery()
      resetInterval()
      orderActions.setImageToBeAllowedOverwrite(null)
    }
    layoutActions.updateDialogModalOpened(false)
  }

  const onUpdateOrderStatus = async (endpoint) => {
    orderActions.updateOrderStatus({
      is_admin: userState.user.is_admin,
      endpoint,
      body: {
        order_id: orderState.orderId,
      },
    }).then(() => {
      layoutActions.updateDialogModalOpened(false)
      refreshGallery().then(() => { })
    })
  }

  const [dialogProps, setDialogProps] = useState({
    onClickCloseIcon: () => layoutActions.updateDialogModalOpened(false),
    onClickOutside: () => layoutActions.updateDialogModalOpened(false),
    onClickCancel: () => layoutActions.updateDialogModalOpened(false),
    title: Translation.delete_image,
    yesLabel: Translation.ok,
    cancelLabel: Translation.cancel,
    onClickYes: onDeleteImages,
    content: (
      <Typography
        variant="xs"
        label={Translation.sure_to_delete_image}
      />
    ),
  })

  const DEFAULT_ORDER_STATUS_PROPS = {
    onClickCloseIcon: () => layoutActions.updateDialogModalOpened(false),
    onClickOutside: () => layoutActions.updateDialogModalOpened(false),
    onClickCancel: () => layoutActions.updateDialogModalOpened(false),
    yesLabel: Translation.yes,
    cancelLabel: Translation.cancel,
    content: null,
  }

  useEffect(() => {
    if (layoutState.isDialogModalOpened) {
      switch (layoutState.dialogModalState) {
        case DIALOG_NAMES.deleteImage:
          setDialogProps({
            onClickCloseIcon: () => layoutActions.updateDialogModalOpened(false),
            onClickOutside: () => layoutActions.updateDialogModalOpened(false),
            onClickCancel: () => layoutActions.updateDialogModalOpened(false),
            title: Translation.delete_image,
            yesLabel: Translation.ok,
            cancelLabel: Translation.cancel,
            onClickYes: onDeleteImages,
            content: (
              <Typography
                variant="xs"
                label={Translation.sure_to_delete_image}
              />
            ),
          })
          break
        case DIALOG_NAMES.deleteComment:
          setDialogProps({
            onClickCloseIcon: () => layoutActions.updateDialogModalOpened(false),
            onClickOutside: () => layoutActions.updateDialogModalOpened(false),
            onClickCancel: () => layoutActions.updateDialogModalOpened(false),
            title: Translation.delete_comments,
            yesLabel: Translation.ok,
            cancelLabel: Translation.cancel,
            onClickYes: onDeleteComments,
            content: (
              <Typography
                variant="xs"
                label={Translation.sure_to_delete_comments}
              />
            ),
          })
          break
        case DIALOG_NAMES.addComment:
          setDialogProps({
            onClickCloseIcon: () => layoutActions.updateDialogModalOpened(false),
            onClickOutside: () => layoutActions.updateDialogModalOpened(false),
            onClickCancel: () => layoutActions.updateDialogModalOpened(false),
            title: Translation.add_comment_selected_images,
            yesLabel: Translation.add,
            cancelLabel: Translation.cancel,
            onClickYes: () => onAddComment(),
            content: (
              <textarea
                className="comment-textarea"
                placeholder={`${Translation.leave_your_comment}...`}
                ref={commentTextareaRef}
                onKeyDown={onCommentSubmit}
              />
            ),
          })
          break
        case DIALOG_NAMES.approveComment:
          setDialogProps({
            onClickCloseIcon: () => layoutActions.updateDialogModalOpened(false),
            onClickOutside: () => layoutActions.updateDialogModalOpened(false),
            onClickCancel: () => layoutActions.updateDialogModalOpened(false),
            title: Translation.approve_clients_comments,
            yesLabel: Translation.ok,
            cancelLabel: Translation.cancel,
            onClickYes: onApproveComments,
            content: (
              <Typography
                variant="xs"
                label={Translation.sure_to_approve_clients_comments}
              />
            ),
          })
          break
        case DIALOG_NAMES.removeUploadedByClient:
          setDialogProps({
            onClickCloseIcon: () => layoutActions.updateDialogModalOpened(false),
            onClickOutside: () => layoutActions.updateDialogModalOpened(false),
            onClickCancel: () => layoutActions.updateDialogModalOpened(false),
            title: `${Translation.attention}!`,
            yesLabel: Translation.ok,
            cancelLabel: Translation.cancel,
            onClickYes: onClearUploads,
            content: (
              <>
                <Typography
                  variant="xs"
                  label={Translation.sp_overwriting_dialog_title}
                />
                <br />
                <Typography
                  variant="xs"
                  label={Translation.the_image_has_comments}
                />
                <Typography
                  variant="xs"
                  label={Translation.you_get_images_from_sp_again}
                />
                <Typography
                  variant="xs"
                  label={Translation.there_is_a_newer_version_to_this_image}
                />
              </>
            ),
          })
          break
        case DIALOG_NAMES.orderStatusRedo:
          setDialogProps({
            ...DEFAULT_ORDER_STATUS_PROPS,
            title: Translation.set_order_status_to_redo,
            onClickYes: () => onUpdateOrderStatus(API_ENDPOINTS_GALLERY.redo),
          })
          break
        case DIALOG_NAMES.orderStatusApprove:
          setDialogProps({
            ...DEFAULT_ORDER_STATUS_PROPS,
            title: Translation.set_order_status_to_approve,
            onClickYes: () => onUpdateOrderStatus(API_ENDPOINTS_GALLERY.approvalRequired),
          })
          break
        case DIALOG_NAMES.orderStatusReDeliverChanges:
          setDialogProps({
            ...DEFAULT_ORDER_STATUS_PROPS,
            title: Translation.set_order_status_to_re_deliver_changes,
            onClickYes: () => onUpdateOrderStatus(API_ENDPOINTS_GALLERY.redelivery),
          })
          break
        case DIALOG_NAMES.orderStatusPartialDelivery:
          setDialogProps({
            ...DEFAULT_ORDER_STATUS_PROPS,
            title: Translation.set_order_status_to_partial_delivery,
            onClickYes: () => onUpdateOrderStatus(API_ENDPOINTS_GALLERY.partialDelivery),
          })
          break
        case DIALOG_NAMES.orderStatusSendInputComments:
          setDialogProps({
            ...DEFAULT_ORDER_STATUS_PROPS,
            title: Translation.set_order_status_to_send_input_comments,
            onClickYes: () => onUpdateOrderStatus(API_ENDPOINTS_GALLERY.clientQaInput),
          })
          break
        default:
          setDialogProps({
            onClickCloseIcon: () => layoutActions.updateDialogModalOpened(false),
            onClickOutside: () => layoutActions.updateDialogModalOpened(false),
            onClickCancel: () => layoutActions.updateDialogModalOpened(false),
            title: Translation.delete_image,
            yesLabel: Translation.ok,
            cancelLabel: Translation.cancel,
            onClickYes: onDeleteImages,
            content: (
              <Typography
                variant="xs"
                label={Translation.sure_to_delete_image}
              />
            ),
          })
          break
      }

      if (layoutState.dialogModalState === DIALOG_NAMES.addComment) {
        setTimeout(() => {
          commentTextareaRef?.current?.focus()
        }, 100)
      }
    }
  }, [])

  useEventListener('keyup', (e) => {
    if (e.key === 'Enter' && layoutState.isDialogModalOpened) {
      if (layoutState.dialogModalState === DIALOG_NAMES.deleteImage) {
        onDeleteImages().then(() => {
        })
      } else if (layoutState.dialogModalState === DIALOG_NAMES.deleteComment) {
        onDeleteComments().then(() => {
        })
      } else if (layoutState.dialogModalState === DIALOG_NAMES.approveComment) {
        onApproveComments().then(() => {
        })
      } else if (layoutState.dialogModalState === DIALOG_NAMES.removeUploadedByClient) {
        onClearUploads().then(() => {
        })
      }
    }
  })

  return (
    <Dialog
      isShown={layoutState.isDialogModalOpened}
      onClickCloseIcon={dialogProps.onClickCloseIcon}
      onClickOutside={dialogProps.onClickOutside}
      isCentered
      onClickCancel={dialogProps.onClickCancel}
      title={dialogProps.title}
      yesLabel={dialogProps.yesLabel}
      cancelLabel={dialogProps.cancelLabel}
      onClickYes={dialogProps.onClickYes}
      content={dialogProps.content}
    />
  )
}

Dialogs.propTypes = {
  refreshGallery: PropTypes.func,
  resetInterval: PropTypes.func,
}

Dialogs.defaultProps = {
  refreshGallery: () => {
  },
  resetInterval: () => {
  },
}

export default Dialogs
