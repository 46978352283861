import React from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as CheckboxCheckedSSvg } from '../../svg/checkbox_checked_s_path.svg'
import { ReactComponent as CheckboxUnCheckedSSvg } from '../../svg/checkbox_unchecked_s_path.svg'
import { ReactComponent as CheckboxHalfCheckedSSvg } from '../../svg/checkbox_half_checked_s_path.svg'
import { ReactComponent as CheckboxCheckedLSvg } from '../../svg/checkbox_checked_l_path.svg'
import { ReactComponent as CheckboxUnCheckedLSvg } from '../../svg/checkbox_unchecked_l_path.svg'
import { ReactComponent as CheckboxHalfCheckedLSvg } from '../../svg/checkbox_half_checked_l_path.svg'

import './index.scss'

const Checkbox = ({
  label,
  id,
  checked,
  halfChecked,
  size,
  onChange,
}) => (
  <div className="checkbox-container">
    <input
      id={id}
      type="checkbox"
      checked={checked}
      onChange={onChange}
    />
    <label htmlFor={id} className="checkbox-icons">
      {
        // eslint-disable-next-line no-nested-ternary
        size === 's' && (checked
          ? <CheckboxCheckedSSvg />
          : halfChecked
            ? <CheckboxHalfCheckedSSvg />
            : <CheckboxUnCheckedSSvg />)
      }
      {
        // eslint-disable-next-line no-nested-ternary
        size === 'l' && (checked
          ? <CheckboxCheckedLSvg />
          : halfChecked
            ? <CheckboxHalfCheckedLSvg />
            : <CheckboxUnCheckedLSvg />)
      }
    </label>
    <label htmlFor={id} className="checkbox-label">
      {label}
    </label>
  </div>
)

Checkbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  id: PropTypes.string,
  checked: PropTypes.bool,
  halfChecked: PropTypes.bool,
  size: PropTypes.oneOf(['s', 'l']),
  onChange: PropTypes.func,
}

Checkbox.defaultProps = {
  label: '',
  id: '',
  checked: false,
  halfChecked: false,
  size: 's',
  onChange: () => {
  },
}

export default Checkbox
