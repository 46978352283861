import React from 'react'
import PropTypes from 'prop-types'

import { Translation } from '../../../../../helpers/Translation'

import Typography from '../../../../../components/Typography'

const ImageColorDepth = ({
  isOriginalImage,
  originalImageDetails,
  image,
}) => {
  const colorDepth = isOriginalImage
    ? originalImageDetails?.colour_depth
    : image.colour_depth

  return (
    <Typography label={`${Translation.colour_depth}: ${colorDepth} ${Translation.bits}`} />
  )
}

ImageColorDepth.propTypes = {
  isOriginalImage: PropTypes.bool.isRequired,
  originalImageDetails: PropTypes.shape({
    colour_depth: PropTypes.string,
  }),
  image: PropTypes.shape({
    colour_depth: PropTypes.string,
  }).isRequired,
}

ImageColorDepth.defaultProps = {
  originalImageDetails: {
    colour_depth: null,
  },
}

export default ImageColorDepth
