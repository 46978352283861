import React, { useEffect, useRef, useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import PropTypes from 'prop-types'

import { Translation } from '../../../helpers/Translation'
import {
  brokenFilePreview,
  notFoundPreview,
  addClass,
  debounce,
  dragElement,
  isAltKey,
  parseFloatRound,
  removeClass,
  useEventListener,
  buildGrids,
  buildPath,
  updateVerticalGridLine,
  updateHorizontalGridLine,
  setSvgSizeAndViewBox,
  getGridColor,
  removeErrorClasses,
  getIsExpanded,
} from '../../../helpers/Utils'
import {
  DEFAULT_OVERLAY_IMAGE_SIZES,
  EMPTY_IMG,
  IMAGE_SIZES,
  IMAGE_STATUSES,
  IMAGE_TYPES,
  PATH_COLORS,
  PATH_WIDTH_MIN,
  PATH_WIDTH_MAX,
  PX_TO_REM,
  SKELETON_CLASS,
  TRANSPARENT_BG,
  VERTICAL_GRID_CLASS,
  HORIZONTAL_GRID_CLASS,
  SHOW_PATH_CLASS,
  OVERLAY_IMG_CLASS,
  OVERLAY_GRID_CLASS,
  NOSELECT_CLASS,
  ACTIVE_CLASS,
  PATH_DISPLAY_RATIO,
  ALL_LAYERS_KEY,
  DISABLED_CLASS,
  ZOOM_SPEED,
  SCALE_TIMES,
  IMAGE_OVERLAY_BORDER,
  OVERLAY_GUIDE_CLASS,
  OVERLAY_WRAP_CLASS,
  OVERLAY_DRAWING_CLASS,
  OVERLAY_UNDERLAY_CLASS,
  OVERLAY_CONTRAST_CLASS,
  TRANSPARENT_KEY,
  EXPANDED_CLASS,
} from '../../../helpers/Constants'

import Typography from '../../../components/Typography'
import Range from '../../../components/Range'
import Checkbox from '../../../components/Checkbox'
import Toggle from '../../../components/Toggle'
import RadioGroup from '../../../components/RadioGroup'
import DualRange from '../../../components/DualRange'
import ColorPicker from '../../../components/ColorPicker'
import Tooltip from '../../../components/Tooltip'

import OverlayAvatars from './OverlayAvatars'
import CommentOverlay from './CommentOverlay'
import CommentSuggestionOverlay from './CommentSuggestionOverlay'
import HistoryOverlay from './HistoryOverlay'
import Background from './Background'
import Shortcuts from './Shortcuts'
import CollapsibleCommentOverlay from './CollapsibleCommentOverlay'
import CollapsibleImageInfo from './CollapsibleImageInfo'
import Comment from './Comment'
import ImageRatio from './ImageInfos/ImageRatio'
import ImageFileName from './ImageInfos/ImageFileName'
import ImageDimensions from './ImageInfos/ImageDimensions'
import ImageColorSpace from './ImageInfos/ImageColorSpace'
import ImageBackgroundColor from './ImageInfos/ImageBackgroundColor'
import ImageColorDepth from './ImageInfos/ImageColorDepth'

import { ReactComponent as OverlayCloseIconSvg } from '../../../svg/overlay_close_icon.svg'
import { ReactComponent as OverlayLeftArrowIconSvg } from '../../../svg/overlay_right_arrow_icon.svg'
import { ReactComponent as OverlayCompareIconSvg } from '../../../svg/overlay_compare_icon.svg'
import { ReactComponent as OverlayDrawingIconSvg } from '../../../svg/overlay_drawing_icon.svg'
import { ReactComponent as OverlayDragIconSvg } from '../../../svg/overlay_drag_icon.svg'
import { ReactComponent as OverlayMagnifyIconSvg } from '../../../svg/overlay_magnify_icon.svg'
import { ReactComponent as OverlayNextIconSvg } from '../../../svg/overlay_next_icon.svg'
import { ReactComponent as OverlayPrevIconSvg } from '../../../svg/overlay_prev_icon.svg'
import { ReactComponent as CategoryIconSvg } from '../../../svg/category.svg'
import { ReactComponent as RedoIconSvg } from '../../../svg/repeat.svg'
import { ReactComponent as RocketIconSvg } from '../../../svg/rocket.svg'
import { ReactComponent as PathOutsideImageIconSvg } from '../../../svg/path_outside_image.svg'
import { ReactComponent as LayerMaskIconSvg } from '../../../svg/layer_mask.svg'
import { ReactComponent as LayerVisibleIconSvg } from '../../../svg/layer_visible.svg'
import { ReactComponent as LayerInvisibleIconSvg } from '../../../svg/layer_invisible.svg'

import './index.scss'

const Overlay = ({ refreshGallery, resetInterval }) => {
  const userState = useStoreState((state) => ({
    user: state.user.user,
  }))

  const layoutState = useStoreState((state) => ({
    isOverlayOpened: state.layout.isOverlayOpened,
    isShortcutsOpened: state.layout.isShortcutsOpened,
    leftPanelWidth: state.layout.leftPanelWidth,
    rightPanelWidth: state.layout.rightPanelWidth,
    pathsColors: state.layout.pathsColors,
  }))

  const layoutActions = useStoreActions((actions) => ({
    updateOverlayOpened: actions.layout.updateOverlayOpened,
    updateShortcutsOpened: actions.layout.updateShortcutsOpened,
    updateRedoModalOpened: actions.layout.updateRedoModalOpened,
    updatePathsColors: actions.layout.updatePathsColors,
  }))

  const orderState = useStoreState((state) => ({
    images: state.order.images,
    imageDetails: state.order.imageDetails,
    overlayImgIndex: state.order.overlayImgIndex,
    imageType: state.order.imageType,
    layerUrls: state.order.layerUrls,
    paths: state.order.paths,
    pathRange: state.order.pathRange,
    gallery: state.order.gallery,
    selectedImagePaths: state.order.selectedImagePaths,
    selectedGrids: state.order.selectedGrids,
    orderViewingUsers: state.order.orderViewingUsers,
    selectedLayers: state.order.selectedLayers,
    selectedUnderlayUrl: state.order.selectedUnderlayUrl,
    selectedUnderlayWidth: state.order.selectedUnderlayWidth,
    selectedUnderlayHeight: state.order.selectedUnderlayHeight,
    drawingType: state.order.drawingType,
    galleryBackground: state.order.galleryBackground,
    previewsBackground: state.order.previewsBackground,
    isBackgroundFromOrderFormat: state.order.isBackgroundFromOrderFormat,
    imageCommentText: state.order.imageCommentText,
  }))

  const orderActions = useStoreActions((actions) => ({
    setOverlayImgIndex: actions.order.setOverlayImgIndex,
    getImageDetails: actions.order.getImageDetails,
    removeError: actions.order.removeError,
    getPath: actions.order.getPath,
    setPathRange: actions.order.setPathRange,
    setSelectedImagePaths: actions.order.setSelectedImagePaths,
    setSelectedGrids: actions.order.setSelectedGrids,
    setSelectedLayers: actions.order.setSelectedLayers,
    setDrawingType: actions.order.setDrawingType,
  }))

  const initOverlayImgPos = { top: 0, left: 0 }
  const fullZoomValue = 100
  const minContrast = 0
  const maxContrast = 255

  const [overlayImgSize, setOverlayImgSize] = useState(DEFAULT_OVERLAY_IMAGE_SIZES)
  const [zoomValue, setZoomValue] = useState(fullZoomValue)
  const [isNotFound, setIsNotFound] = useState(false)
  const [isBroken, setIsBroken] = useState(false)
  const [imageElements, setImageElements] = useState({})
  const [overlaySrc, setOverlaySrc] = useState(EMPTY_IMG)
  const [isOriginalImage, setIsOriginalImage] = useState(false)
  const [isSpaceRequired, setIsSpaceRequired] = useState(true)
  const [isDrawingActive, setIsDrawingActive] = useState(true)
  const [originalImageDetails, setOriginalImageDetails] = useState({})
  const [imagePaths, setImagePaths] = useState({})
  const [layerValues, setLayerValues] = useState({})
  const [openColorPicker, setOpenColorPicker] = useState(false)
  const [colorSelectingPath, setColorSelectingPath] = useState(null)
  const [isMagnifierActive, setIsMagnifierActive] = useState(false)
  const [contrastValue, setContrastValue] = useState({ min: minContrast, max: maxContrast })
  const [commentSuggestions, setCommentSuggestions] = useState([])
  const showInterfaceInit = JSON.parse(localStorage.getItem('showInterface')) !== null
    ? JSON.parse(localStorage.getItem('showInterface')) : true
  const [showInterface, setShowInterface] = useState(showInterfaceInit)
  const [isExpanded, setIsExpanded] = useState(getIsExpanded())

  const imageSizesRef = useRef(DEFAULT_OVERLAY_IMAGE_SIZES)
  const overlayContainerRef = useRef(null)
  const overlayImageWrapRef = useRef(null)
  const overlayImgRef = useRef(null)
  const overlayImgIndexRef = useRef(null)
  const overlayImgContrastRef = useRef(null)
  const overlayImgDrawingRef = useRef(null)
  const imageOverlayImgCorsRef = useRef(null)
  const dragEventRef = useRef(null)
  const originalImageDataRef = useRef(null)
  const contrastCtxRef = useRef(null)
  const overlayUnderlayRef = useRef(null)

  const isMagnifierInitRef = useRef(false)
  const isMagnifierActiveRef = useRef(false)
  const magnifierOverlayRef = useRef(null)
  const resizeRatioRef = useRef(null)
  const isToFullSize = useRef(false)

  const minWheelScaleRef = useRef(1)
  const zoomDeltaRef = useRef(0) // To accumulate delta values
  const wheelAnimFrameIdRef = useRef(null) // To store requestAnimationFrame ID
  const mouseXRef = useRef(0) // Mouse X position relative to the image
  const mouseYRef = useRef(0) // Mouse Y position relative to the image

  const historyWrapRef = useRef(null)
  const historyItemsCount = useRef(0)

  useEffect(() => {
    localStorage.setItem('showInterface', showInterface)
  }, [showInterface])

  useEffect(() => {
    if (!layoutState.isOverlayOpened) return

    overlayImgIndexRef.current = orderState.overlayImgIndex
  }, [orderState.overlayImgIndex])

  const getDetails = (index = null, isOriginal = false) => {
    let previousImage = {}
    if (index || orderState.overlayImgIndex > 0) previousImage = orderState.images[(index || orderState.overlayImgIndex) - 1]

    let nextImage = {}
    if (index || orderState.overlayImgIndex < (orderState.images.length - 1)) {
      nextImage = orderState.images[(index || orderState.overlayImgIndex) + 1]
    }

    if (isOriginal) {
      const inPayload = {
        image_ids: [],
        order_ids: [],
        image_type: IMAGE_TYPES.input,
        image_size: IMAGE_SIZES.extraLarge,
        is_with_comments: isExpanded.history,
      }

      if (previousImage.id && !orderState.imageDetails[previousImage.original_image_id]) {
        inPayload.image_ids.push(parseInt(previousImage.original_image_id, 10))
        inPayload.order_ids.push(previousImage.order_id)
      }

      if (!orderState.imageDetails[orderState.images[index || orderState.overlayImgIndex].original_image_id]) {
        inPayload.image_ids.push(parseInt(orderState.images[index || orderState.overlayImgIndex].original_image_id, 10))
        inPayload.order_ids.push(orderState.images[index || orderState.overlayImgIndex].order_id)
      }

      if (nextImage?.id && !orderState.imageDetails[nextImage.original_image_id]) {
        inPayload.image_ids.push(parseInt(nextImage.original_image_id, 10))
        inPayload.order_ids.push(nextImage.order_id)
      }

      if (inPayload.image_ids.length) orderActions.getImageDetails(inPayload)
    }

    const outPayload = {
      image_ids: [],
      order_ids: [],
      image_type: orderState.imageType,
      image_size: IMAGE_SIZES.extraLarge,
      is_with_comments: isExpanded.history,
    }

    if (previousImage.id && !orderState.imageDetails[previousImage.id]) {
      outPayload.image_ids.push(previousImage.id)
      outPayload.order_ids.push(previousImage.order_id)
    }

    if (!orderState.imageDetails[orderState.images[index || orderState.overlayImgIndex].id]) {
      outPayload.image_ids.push(orderState.images[index || orderState.overlayImgIndex].id)
      outPayload.order_ids.push(orderState.images[index || orderState.overlayImgIndex].order_id)
    }

    if (nextImage?.id && !orderState.imageDetails[nextImage.id]) {
      outPayload.image_ids.push(nextImage.id)
      outPayload.order_ids.push(nextImage.order_id)
    }

    if (outPayload.image_ids.length) orderActions.getImageDetails(outPayload)
  }

  const getOverlaySrc = () => {
    const overlayImage = orderState.images[orderState.overlayImgIndex]
    if (!overlayImage) return EMPTY_IMG

    if (isOriginalImage) return imageElements?.[overlayImage.original_image_id]?.src || EMPTY_IMG

    const currentImageSrc = imageElements?.[overlayImage.id]?.src
    if (!currentImageSrc) return EMPTY_IMG

    const selectedLayer = orderState.selectedLayers[overlayImage.id]
    const isAllLayersSelected = selectedLayer === `${ALL_LAYERS_KEY}-${overlayImage.id}` || !selectedLayer
    if (isAllLayersSelected) return imageElements?.[overlayImage.id]?.src || EMPTY_IMG

    return (orderState.layerUrls?.[overlayImage.id]?.[selectedLayer] || EMPTY_IMG)
  }

  const getImageSizes = () => {
    const getDimension = (dimension) => {
      if (isOriginalImage) {
        const image = orderState.images[orderState.overlayImgIndex]
        return orderState.imageDetails[image.original_image_id]?.[dimension] || DEFAULT_OVERLAY_IMAGE_SIZES[dimension]
      }
      return orderState.images[overlayImgIndexRef.current]?.[dimension] || DEFAULT_OVERLAY_IMAGE_SIZES[dimension]
    }

    const width = getDimension('width')
    const height = getDimension('height')
    // console.log('isOriginalImage', isOriginalImage, { width, height })

    return { width, height }
  }

  const getOverlayContainerSizes = () => {
    let width = overlayContainerRef.current?.offsetWidth || DEFAULT_OVERLAY_IMAGE_SIZES.width
    let height = overlayContainerRef.current?.offsetHeight || DEFAULT_OVERLAY_IMAGE_SIZES.height

    // add borders only if background is coming from order format
    if (orderState.isBackgroundFromOrderFormat) {
      if (width) width -= (2 * IMAGE_OVERLAY_BORDER)
      if (height) height -= (2 * IMAGE_OVERLAY_BORDER)
    }

    return { width, height }
  }

  const getCenterPosition = () => {
    const pos = initOverlayImgPos

    const overlayContainerSizes = getOverlayContainerSizes()
    if (overlayContainerSizes.width <= 0 || overlayContainerSizes.height <= 0) return pos

    let scrollLeft = parseFloatRound((overlayImgSize.width - overlayContainerSizes.width) / 2)
    let scrollTop = parseFloatRound((overlayImgSize.height - overlayContainerSizes.height) / 2)

    // add borders only if background is coming from order format
    if (orderState.isBackgroundFromOrderFormat) {
      scrollLeft -= IMAGE_OVERLAY_BORDER
      scrollTop -= IMAGE_OVERLAY_BORDER
    }

    pos.left = -1 * scrollLeft
    pos.top = -1 * scrollTop

    return pos
  }

  const positionToCenter = (shiftTop = 0, shiftLeft = 0) => {
    const pos = getCenterPosition()
    overlayImageWrapRef.current.style.top = `${pos.top + shiftTop}px`
    overlayImageWrapRef.current.style.left = `${pos.left + shiftLeft}px`
  }

  const getResizeRatio = () => {
    let ratio = 1

    if (!imageSizesRef.current.width || !imageSizesRef.current.height) return ratio

    const overlayContainerSizes = getOverlayContainerSizes()
    if (overlayContainerSizes.width <= 0 || overlayContainerSizes.height <= 0) return ratio

    const ratioW = overlayContainerSizes.width / imageSizesRef.current.width
    const ratioH = overlayContainerSizes.height / imageSizesRef.current.height

    ratio = ratioW > ratioH ? ratioH : ratioW
    if (ratio > 1) ratio = 1

    return ratio
  }

  const updateGrids = () => {
    const image = orderState.images[orderState.overlayImgIndex]

    orderState.selectedGrids.forEach((selectedGrid) => {
      const gridIndex = orderState.gallery?.grids?.findIndex((grid) => grid.name === selectedGrid.name)

      document.querySelectorAll(`.${OVERLAY_GRID_CLASS}.grid-${gridIndex}`).forEach((gridElement) => {
        setSvgSizeAndViewBox(
          gridElement,
          image.width,
          image.height,
        )

        gridElement.querySelectorAll(`.${VERTICAL_GRID_CLASS}`).forEach((lineElement, i) => {
          updateVerticalGridLine(
            lineElement,
            orderState.gallery?.grids[gridIndex].left[i],
            image.width,
            image.height,
          )
        })

        gridElement.querySelectorAll(`.${HORIZONTAL_GRID_CLASS}`).forEach((lineElement, i) => {
          updateHorizontalGridLine(
            lineElement,
            orderState.gallery?.grids[gridIndex].top[i],
            image.width,
            image.height,
          )
        })
      })
    })
  }

  useEffect(() => {
    if (!layoutState.isOverlayOpened) return

    const image = orderState.images[orderState.overlayImgIndex]
    setImagePaths(image.path)
    document.querySelectorAll(`.${SHOW_PATH_CLASS}`).forEach((path) => path.remove())

    updateGrids()

    addClass(overlayImgRef.current, SKELETON_CLASS)
    if (overlayImgRef.current?.src?.includes(notFoundPreview) || overlayImgRef.current?.src?.includes(brokenFilePreview)) {
      removeClass(overlayImgRef.current, SKELETON_CLASS)
    }
  }, [layoutState.isOverlayOpened, orderState.overlayImgIndex])

  const [isHistory, setIsHistory] = useState(false)
  const [isVersions, setIsVersions] = useState(false)

  useEffect(() => {
    if (!layoutState.isOverlayOpened) return

    const image = orderState.images[orderState.overlayImgIndex]
    setOriginalImageDetails(orderState.imageDetails[image.original_image_id])

    const imageDetails = orderState.imageDetails[image.id]
    if (imageDetails) {
      setIsHistory(imageDetails?.image_history?.length > 0 || imageDetails.comment_count > 0)
      setIsVersions(imageDetails?.image_versions?.length > 0)

      historyItemsCount.current = (
        imageDetails?.image_history?.length
        || imageDetails?.image_versions?.length
        || imageDetails.comment_count)
    }
  }, [orderState.imageDetails, orderState.overlayImgIndex])

  useEffect(() => {
    const image = orderState.images[orderState.overlayImgIndex]
    const layers = {}
    image?.layer?.forEach((layer) => {
      layers[layer.id] = (
        <div className="layer-item">
          {parseInt(layer.is_visible, 2) ? <LayerVisibleIconSvg /> : <LayerInvisibleIconSvg />}
          {parseInt(layer.has_layer_mask, 2) ? <LayerMaskIconSvg /> : null}
          <Typography label={layer.name.replace(/&nbsp;/g, ' ')} />
        </div>
      )
    })
    setLayerValues({
      [`${ALL_LAYERS_KEY}-${image?.id}`]: (
        <div className="layer-item">
          <Typography label={Translation.all_layers} />
        </div>
      ),
      ...layers,
    })
  }, [orderState.images[orderState.overlayImgIndex]])

  useEffect(() => {
    Object.keys(orderState.imageDetails).forEach((detail) => {
      if (detail) {
        const image = new Image()
        image.src = orderState.imageDetails[detail].url
        image.onload = () => {
          setImageElements((prevImageElements) => ({
            ...prevImageElements,
            [orderState.imageDetails[detail].id]: image,
          }))
        }
        image.onerror = () => {
          if (parseInt(orderState.imageDetails[detail].status, 10) === IMAGE_STATUSES.broken) {
            image.src = brokenFilePreview
          } else {
            image.src = notFoundPreview
          }
        }
      }
    })
  }, [orderState.imageDetails])

  useEffect(() => {
    if (!layoutState.isOverlayOpened) return

    positionToCenter()

    document.querySelectorAll(`.${SHOW_PATH_CLASS}`).forEach((path) => {
      path?.setAttribute('style', `width: ${overlayImgSize.width}px; height: ${overlayImgSize.height}px;`)
    })

    document.querySelectorAll(`.${OVERLAY_GRID_CLASS}`).forEach((grid) => {
      grid?.setAttribute('style', `width: ${overlayImgSize.width}px; height: ${overlayImgSize.height}px;`)
    })
  }, [overlayImgSize])

  useEffect(() => {
    if (overlayImgRef.current?.src?.includes(notFoundPreview)) {
      setIsNotFound(true)
    } else if (overlayImgRef.current?.src?.includes(brokenFilePreview)) {
      setIsBroken(true)
    } else {
      setIsNotFound(false)
      setIsBroken(false)
    }
  }, [overlayImgRef.current?.src])

  const renderImageInCanvas = () => {
    if (!contrastCtxRef.current) return
    if (!overlayImgContrastRef.current) return

    contrastCtxRef.current.clearRect(
      0,
      0,
      overlayImgContrastRef.current.width,
      overlayImgContrastRef.current.height,
    )
    contrastCtxRef.current.drawImage(
      imageOverlayImgCorsRef.current,
      0,
      0,
      overlayImgContrastRef.current.width,
      overlayImgContrastRef.current.height,
    )
    originalImageDataRef.current = contrastCtxRef.current.getImageData(
      0,
      0,
      overlayImgContrastRef.current.width,
      overlayImgContrastRef.current.height,
    )
  }

  const createImageWithCorsForContrast = (url) => {
    if (!isExpanded.contrast) return
    if (!orderState.images[orderState.overlayImgIndex]?.id) return

    const image = orderState.images[orderState.overlayImgIndex]

    if (url.includes(EMPTY_IMG)) return
    if (url.includes(notFoundPreview)) return
    if (url.includes(brokenFilePreview)) return

    if (!url.includes('.cloudfront.net')) return // TODO: cloudfront is expected to have valid url

    // Append something unique to the URL to force browser to load image again
    const urlForCors = `${url}?${image?.id}`

    imageOverlayImgCorsRef.current = new Image()
    imageOverlayImgCorsRef.current.crossOrigin = 'Anonymous' // Allows cross-origin loading
    imageOverlayImgCorsRef.current.src = urlForCors

    imageOverlayImgCorsRef.current.addEventListener('load', () => {
      renderImageInCanvas()
    })
  }

  useEffect(() => {
    if (!layoutState.isOverlayOpened) return

    if (isExpanded.contrast) {
      contrastCtxRef.current = overlayImgContrastRef.current.getContext('2d', {
        willReadFrequently: true,
      })
      contrastCtxRef.current.imageSmoothingEnabled = false
      createImageWithCorsForContrast(overlayImgRef.current.src)
    }
  }, [isExpanded.contrast])

  const handleOverlayImgLoad = () => {
    if (overlayImgRef.current.src !== `${window.location.origin}${EMPTY_IMG}`) {
      removeClass(overlayImgRef.current, SKELETON_CLASS)
    }

    createImageWithCorsForContrast(overlayImgRef.current.src)
  }

  const handleOverlayImgLoadError = () => {
    const image = orderState.images[orderState.overlayImgIndex]
    if (orderState.selectedLayers[image?.id]
      !== `${ALL_LAYERS_KEY}-${image?.id}`) {
      removeClass(overlayImgRef.current, SKELETON_CLASS)
      document.querySelector(`.${OVERLAY_IMG_CLASS}`).src = notFoundPreview
    }
  }

  const resetContrast = () => {
    setContrastValue({ min: minContrast, max: maxContrast })
  }

  const handleNextPrevClick = async (e) => {
    const { direction } = e.currentTarget.dataset
    if (direction === '-1' && orderState.overlayImgIndex === 0) return
    if (direction === '1' && orderState.overlayImgIndex === orderState.images.length - 1) return
    orderActions.setOverlayImgIndex(orderState.overlayImgIndex + parseInt(direction, 10))
    getDetails(orderState.overlayImgIndex + parseInt(direction, 10), isOriginalImage)
    resetContrast()
  }

  const onCloseOverlay = () => {
    layoutActions.updateOverlayOpened(false)
  }

  useEventListener('keyup', (e) => {
    if (!layoutState.isOverlayOpened) return

    if (e.target.tagName === 'TEXTAREA') return

    if (e.key === 'ArrowLeft') {
      if (orderState.overlayImgIndex > 0) {
        handleNextPrevClick({ currentTarget: { dataset: { direction: '-1' } } }).then(() => {
        })
      }
    } else if (e.key === 'ArrowRight') {
      if (orderState.overlayImgIndex < orderState.images.length - 1) {
        handleNextPrevClick({ currentTarget: { dataset: { direction: '1' } } }).then(() => {
        })
      }
    } else if (e.key === 'Escape') {
      onCloseOverlay()
    }
  })

  const toggleSpaceRequired = () => {
    setIsSpaceRequired(!isSpaceRequired)

    overlayImageWrapRef?.current.removeEventListener('mousedown', dragEventRef.current)
    dragEventRef.current = dragElement(overlayImageWrapRef.current, !isSpaceRequired)
    overlayImageWrapRef.current.addEventListener('mousedown', dragEventRef.current)
  }

  const toggleOriginalImage = () => {
    const image = orderState.images[orderState.overlayImgIndex]
    if (image.original_image_id) {
      addClass(document.querySelector(`.${OVERLAY_IMG_CLASS}`), SKELETON_CLASS)
      setIsOriginalImage(!isOriginalImage)
      getDetails(orderState.overlayImgIndex, !isOriginalImage)
    }
  }

  const handleOverlayDrawingClick = () => {
    if (!isDrawingActive) {
      isMagnifierInitRef.current = false
      isMagnifierActiveRef.current = false
      setIsMagnifierActive(false)
    }

    setIsDrawingActive(!isDrawingActive)
  }

  const expand = (key) => {
    setIsExpanded(() => ({
      ...isExpanded,
      [key]: !isExpanded[key],
    }))

    localStorage.setItem('isExpanded', JSON.stringify(
      {
        ...isExpanded,
        [key]: !isExpanded[key],
      },
    ))

    if (key === 'contrast' && isExpanded.contrast) {
      overlayImgContrastRef.current.style.display = 'none'
    }
  }

  const removeErrorCode = async (e, errorCode) => {
    if (!userState.user.is_admin) return

    if (isAltKey(e)) {
      e.stopPropagation()

      const image = orderState.images[orderState.overlayImgIndex]
      removeErrorClasses(image, e.currentTarget, errorCode)

      const res = await orderActions.removeError({
        is_admin: userState.user.is_admin,
        body: {
          image_ids: [image.id],
          error_codes: [errorCode],
        },
      })

      if (res) {
        refreshGallery()
        resetInterval()
      }
    }
  }

  const onChangePathRange = (value) => {
    orderActions.setPathRange(parseInt(value, 10))
  }

  const onSelectPath = async (path) => {
    const image = orderState.images[orderState.overlayImgIndex]
    const selectedImagePathsCopy = { ...orderState.selectedImagePaths }
    if (!selectedImagePathsCopy[image.id]) {
      selectedImagePathsCopy[image.id] = {}
    }
    selectedImagePathsCopy[image.id][path] = !selectedImagePathsCopy[
      image.id
    ][path]
    orderActions.setSelectedImagePaths(selectedImagePathsCopy)

    if (!selectedImagePathsCopy?.[image.id]?.[path]) {
      document.getElementById(`${image.id}-${path}`)?.remove()
      document.getElementById(`${image.id}-${path}-preview`)?.remove()
      return
    }
    if (orderState.paths[image.id]?.[imagePaths[path]?.line.s3_path]) {
      return
    }

    await orderActions.getPath({
      image_ids: [
        image.id,
      ],
      s3_paths: [
        imagePaths[path]?.line.s3_path,
      ],
    })
  }

  const addPath = (pathName) => {
    const image = orderState.images[orderState.overlayImgIndex]
    const elementString = orderState.paths[image.id]
      ?.[image.path?.[pathName]?.line?.s3_path]
    if (!elementString || document.getElementById(`${image.id}-${pathName}`)) return

    const svgElement = buildPath(
      `${image.id}-${pathName}`,
      SHOW_PATH_CLASS,
      elementString,
      overlayImgRef.current.width,
      overlayImgRef.current.height,
      orderState.pathRange,
      pathName,
    )
    document.querySelector(`.${OVERLAY_IMG_CLASS}`).insertAdjacentElement('afterend', svgElement)
  }

  const clickPathOutsideImage = (e) => {
    const toggleIcon = e.currentTarget
    const {
      id, name, viewbox, viewboxRatio,
    } = toggleIcon.dataset

    const svgElement = document.getElementById(`${id}-${name}`)
    if (!svgElement) return

    const isActive = toggleIcon.classList.toggle(ACTIVE_CLASS)
    if (isActive) {
      const viewboxWrapArr = viewbox.split(' ')
      const viewboxArr = svgElement.dataset.viewBox.split(' ')
      let scaledViewbox = ''
      viewboxArr.forEach((item) => {
        scaledViewbox += `${Math.round(parseInt(item, 10) / viewboxRatio)} `
      })
      svgElement.setAttribute('viewBox', scaledViewbox.trim())

      overlayImgRef.current.style.width = `${overlayImgSize.width * viewboxRatio}px`
      overlayImgRef.current.style.height = `${overlayImgSize.height * viewboxRatio}px`
      overlayImgRef.current.style.left = `${(-1 * viewboxWrapArr[0] * resizeRatioRef.current * viewboxRatio)}px`
      overlayImgRef.current.style.top = `${(-1 * viewboxWrapArr[1] * resizeRatioRef.current * viewboxRatio)}px`
    } else {
      svgElement.setAttribute('viewBox', svgElement.dataset.viewBox)

      overlayImgRef.current.style.width = `${overlayImgSize.width}px`
      overlayImgRef.current.style.height = `${overlayImgSize.height}px`
      overlayImgRef.current.style.left = ''
      overlayImgRef.current.style.top = ''
    }
  }

  const onSelectGrid = (grid) => {
    const newSelectedGrids = [...orderState.selectedGrids]
    const index = newSelectedGrids.findIndex((selectedGrid) => selectedGrid.name === grid.name)
    if (index > -1) {
      newSelectedGrids.splice(index, 1)
      document
        .querySelector(`.${OVERLAY_GRID_CLASS}.grid-${orderState.gallery?.grids?.findIndex((g) => g.name === grid.name)}`)
        ?.remove()
      document
        .querySelectorAll(`.${OVERLAY_GRID_CLASS}.grid-${orderState.gallery?.grids?.findIndex((g) => g.name === grid.name)}`)
        ?.forEach((gridElement) => {
          gridElement?.remove()
        })
    } else {
      newSelectedGrids.push(grid)
    }
    orderActions.setSelectedGrids(newSelectedGrids)
  }

  const toggleShortcuts = () => {
    layoutActions.updateShortcutsOpened(!layoutState.isShortcutsOpened)
  }

  const sendInRedo = () => {
    if (!orderState.gallery?.is_send_into_redo) return

    layoutActions.updateRedoModalOpened(true)
  }

  // TODO: looks like we don't need it
  /* const onHandleLayerChange = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const image = orderState.images[orderState.overlayImgIndex]
    orderActions.setSelectedLayers({
      imageId: image.id,
      layerId: e.target.value,
    })
  } */

  useEffect(() => {
    if (!layoutState.isOverlayOpened) return

    const image = orderState.images[orderState.overlayImgIndex]
    orderState.selectedGrids.forEach((grid) => {
      if (document
        .querySelector(`.${OVERLAY_GRID_CLASS}.grid-${orderState.gallery?.grids?.findIndex((g) => g.name === grid.name)}`)
      ) return

      const color = PATH_COLORS[orderState.gallery?.grids?.findIndex((g) => g.name === grid.name)]
      const ratio = getResizeRatio()

      const svgElement = buildGrids(
        grid,
        orderState.gallery?.grids,
        image.width,
        image.height,
        parseFloatRound(image.width * ratio),
        parseFloatRound(image.height * ratio),
        color,
        OVERLAY_GRID_CLASS,
      )

      document.querySelector(`.${OVERLAY_IMG_CLASS}`).insertAdjacentElement('afterend', svgElement)
    })
  }, [orderState.selectedGrids])

  useEffect(() => {
    if (!layoutState.isOverlayOpened) return
    if (!orderState.paths[orderState.images[orderState.overlayImgIndex]?.id]) return

    const image = orderState.images[orderState.overlayImgIndex]
    Object.keys(orderState.selectedImagePaths?.[image?.id] || {})
      .forEach((pathName) => {
        if (orderState.selectedImagePaths?.[image?.id]?.[pathName]) {
          addPath(pathName)
        }
      })
  }, [
    orderState.paths,
    orderState.selectedImagePaths?.[orderState.images[orderState.overlayImgIndex]?.id],
    layoutState.isOverlayOpened,
  ])

  useEffect(() => {
    document.querySelectorAll(`.${SHOW_PATH_CLASS}`).forEach((path) => {
      path?.querySelector('path').setAttribute('stroke-width', orderState.pathRange / PATH_DISPLAY_RATIO)
    })
  }, [orderState.pathRange])

  useEffect(() => {
    document.querySelectorAll(`.${SHOW_PATH_CLASS}`).forEach((path) => {
      if (layoutState.pathsColors[path.dataset.pathName]) {
        path.querySelector('path').setAttribute('stroke', layoutState.pathsColors[path.dataset.pathName])
      }
    })
  }, [layoutState.pathsColors])

  const showFileName = () => {
    if (isExpanded.imageInformation) return true

    const image = orderState.images[orderState.overlayImgIndex]
    const imageDetails = isOriginalImage ? originalImageDetails : image
    return imageDetails?.wrong_file_name || imageDetails?.wrong_format
  }

  const showImageDimensions = () => {
    if (isExpanded.imageInformation) return true

    const image = orderState.images[orderState.overlayImgIndex]
    const imageDetails = isOriginalImage ? originalImageDetails : image
    return imageDetails?.width_error || imageDetails?.height_error || imageDetails?.dpi_error
  }

  const showColorSpace = () => {
    if (isExpanded.imageInformation) return true

    const image = orderState.images[orderState.overlayImgIndex]
    const imageDetails = isOriginalImage ? originalImageDetails : image
    return imageDetails?.color_space_error
  }

  const showBackgroundColor = () => {
    if (isExpanded.imageInformation) return true

    const image = orderState.images[orderState.overlayImgIndex]
    const imageDetails = isOriginalImage ? originalImageDetails : image
    return imageDetails?.no_bg_color
  }

  const showColorDepth = () => {
    if (isExpanded.imageInformation) return true

    const image = orderState.images[orderState.overlayImgIndex]
    const imageDetails = isOriginalImage ? originalImageDetails : image
    return imageDetails?.colour_depth
  }

  const closePicker = () => {
    setColorSelectingPath(null)
    setOpenColorPicker(false)
  }

  const handlePathColorClick = (path) => {
    setColorSelectingPath(path)
    setOpenColorPicker(true)
  }

  const handlePathColorSelect = (color) => {
    layoutActions.updatePathsColors({
      ...layoutState.pathsColors,
      [colorSelectingPath]: color,
    })
  }

  const resize = (element, width, height) => {
    if (!element) return
    // eslint-disable-next-line no-param-reassign
    element.style.width = `${width}px`
    // eslint-disable-next-line no-param-reassign
    element.style.height = `${height}px`
  }

  const setViewBox = (element) => {
    element?.setAttribute(
      'viewBox',
      `0 0 
       ${imageSizesRef.current.width || DEFAULT_OVERLAY_IMAGE_SIZES.width} 
       ${imageSizesRef.current.height || DEFAULT_OVERLAY_IMAGE_SIZES.height}`,
    )
  }

  const resizeSvg = (element, width, height) => {
    if (element) {
      setViewBox(element, width, height)

      // TODO: looks like not need for SVG, because resized correctly with only viewBox
      // element?.setAttribute('width', width)
      // element?.setAttribute('height', height)

      // eslint-disable-next-line no-param-reassign
      element.style.width = `${width}px`
      // eslint-disable-next-line no-param-reassign
      element.style.height = `${height}px`
    }
  }

  const position = (element, left, top) => {
    if (orderState.isBackgroundFromOrderFormat && (left === 0 || top === 0)) {
      return // helps to prevent image jump by "IMAGE_OVERLAY_BORDER"
    }

    // eslint-disable-next-line no-param-reassign
    element.style.left = `${left}px`
    // eslint-disable-next-line no-param-reassign
    element.style.top = `${top}px`
  }

  const resizeAndPosition = (element, width, height, left, top) => {
    resize(element, width, height)
    position(element, left, top)
  }

  const getSizesAndPosition = () => {
    const newResizeRatio = getResizeRatio()

    if (isToFullSize.current) {
      resizeRatioRef.current = 1
      minWheelScaleRef.current = newResizeRatio
      setOverlayImgSize(imageSizesRef.current)
    } else {
      resizeRatioRef.current = newResizeRatio
      minWheelScaleRef.current = newResizeRatio
      setOverlayImgSize({
        width: parseFloatRound(imageSizesRef.current.width * newResizeRatio),
        height: parseFloatRound(imageSizesRef.current.height * newResizeRatio),
      })
    }

    setZoomValue(Math.floor(parseFloatRound(resizeRatioRef.current * 100)))

    const width = parseFloatRound((imageSizesRef.current.width || DEFAULT_OVERLAY_IMAGE_SIZES.width)
      * resizeRatioRef.current)
    const height = parseFloatRound((imageSizesRef.current.height || DEFAULT_OVERLAY_IMAGE_SIZES.height)
      * resizeRatioRef.current)

    const overlayContainerSizes = getOverlayContainerSizes()

    const scrollLeft = parseFloatRound((overlayContainerSizes.width - width) / 2)
    const scrollTop = parseFloatRound((overlayContainerSizes.height - height) / 2)

    return {
      width, height, scrollLeft, scrollTop,
    }
  }

  const resizeUnderlay = (imageWidth, imageHeight) => {
    if (!orderState.selectedUnderlayUrl) return
    if (!overlayUnderlayRef.current) return

    const ratioW = imageWidth / orderState.selectedUnderlayWidth
    const ratioH = imageHeight / orderState.selectedUnderlayHeight

    const ratioUnderlay = (ratioW > ratioH) ? ratioH : ratioW

    const width = parseFloatRound(orderState.selectedUnderlayWidth * ratioUnderlay)
    const height = parseFloatRound(orderState.selectedUnderlayHeight * ratioUnderlay)

    const left = imageWidth > width
      ? `${parseFloatRound((imageWidth - width) / 2)}` : 0
    const top = imageHeight > height
      ? `${parseFloatRound((imageHeight - height) / 2)}` : 0

    resizeAndPosition(overlayUnderlayRef.current, width, height, left, top)
  }

  const resizeAndPositionLayers = () => {
    const {
      width, height, scrollLeft, scrollTop,
    } = getSizesAndPosition()

    resizeUnderlay(width, height)
    resizeAndPosition(overlayImageWrapRef.current, width, height, scrollLeft, scrollTop)
    resize(overlayImgRef.current, width, height)
    resize(overlayImgContrastRef.current, width, height)

    resetContrast()
  }

  const toggleInterface = () => {
    setShowInterface((prev) => !prev)

    setTimeout(() => {
      if (showInterface) {
        // positionToCenter(0, (layoutState.leftPanelWidth - layoutState.rightPanelWidth) / 2)
        isToFullSize.current = false
        resizeAndPositionLayers()
      } else {
        // positionToCenter()
        isToFullSize.current = false
        resizeAndPositionLayers()
      }
    }, 10)
  }

  const applyZoom = () => {
    // Restrict scale between minWheelScale and SCALE_TIMES
    const newWheelScale = Math.min(
      Math.max(
        minWheelScaleRef.current,
        resizeRatioRef.current + zoomDeltaRef.current,
      ),
      SCALE_TIMES,
    )

    // If scale has not changed, skip updates and reset request
    if (newWheelScale === resizeRatioRef.current) {
      zoomDeltaRef.current = 0
      wheelAnimFrameIdRef.current = null
      return
    }

    // Calculate the new dimensions
    const width = parseFloatRound((imageSizesRef.current.width || DEFAULT_OVERLAY_IMAGE_SIZES.width) * newWheelScale)
    const height = parseFloatRound((imageSizesRef.current.height || DEFAULT_OVERLAY_IMAGE_SIZES.height) * newWheelScale)

    // Calculate the new position to keep the mouse point fixed
    const rect = overlayImageWrapRef.current.getBoundingClientRect()
    const dx = (mouseXRef.current / rect.width) * (width - rect.width)
    const dy = (mouseYRef.current / rect.height) * (height - rect.height)

    // Calculate the new left and top of the image
    const scrollLeft = parseFloatRound(overlayImageWrapRef.current.style.left || DEFAULT_OVERLAY_IMAGE_SIZES.width)
      - parseFloatRound(dx)
    const scrollTop = parseFloatRound(overlayImageWrapRef.current.style.top || DEFAULT_OVERLAY_IMAGE_SIZES.height)
      - parseFloatRound(dy)

    resizeUnderlay(width, height)
    resizeAndPosition(overlayImageWrapRef.current, width, height, scrollLeft, scrollTop)
    resize(overlayImgRef.current, width, height)
    resize(overlayImgContrastRef.current, width, height)

    overlayImageWrapRef.current.querySelectorAll('svg').forEach((svg) => {
      resizeSvg(svg, width, height)
    })

    // Update the zoom scale and reset zoom delta
    resizeRatioRef.current = newWheelScale
    zoomDeltaRef.current = 0

    // Update zoom display
    setZoomValue(Math.floor(parseFloatRound(resizeRatioRef.current * 100)))

    // Reset the animation frame ID
    wheelAnimFrameIdRef.current = null
  }

  const onMouseWheel = (e) => {
    e.preventDefault()

    // Accumulate delta for zoom level and get the mouse position
    zoomDeltaRef.current += -e.deltaY / ZOOM_SPEED

    const rect = overlayImageWrapRef.current.getBoundingClientRect()
    mouseXRef.current = e.clientX - rect.left
    mouseYRef.current = e.clientY - rect.top

    // Request an animation frame if not already requested
    if (!wheelAnimFrameIdRef.current) {
      wheelAnimFrameIdRef.current = requestAnimationFrame(applyZoom)
    }
  }

  const onMouseMove = (e) => {
    if (!isMagnifierActiveRef.current) return

    const imageRect = overlayImgRef.current.getBoundingClientRect()
    const magnifierRect = magnifierOverlayRef.current.getBoundingClientRect()

    const imageWidth = imageSizesRef.current.width || DEFAULT_OVERLAY_IMAGE_SIZES.width
    const imageHeight = imageSizesRef.current.height || DEFAULT_OVERLAY_IMAGE_SIZES.height

    const scaleX = imageWidth / imageRect.width
    const scaleY = imageHeight / imageRect.height

    const scrollLeft = parseFloatRound(e.clientX - magnifierRect.width / 2)
    const scrollTop = parseFloatRound(e.clientY - magnifierRect.height / 2)

    const offsetX = -1
      * (parseFloatRound((e.clientX - imageRect.left) * scaleX)
        - parseFloatRound(magnifierRect.width / 2))
    const offsetY = -1
      * (parseFloatRound((e.clientY - imageRect.top) * scaleY)
        - parseFloatRound(magnifierRect.height / 2))

    const magnifierImage = magnifierOverlayRef.current.querySelector(`#${OVERLAY_IMG_CLASS}`)

    position(magnifierOverlayRef.current, scrollLeft, scrollTop)
    position(magnifierImage, offsetX, offsetY)

    const guide = magnifierOverlayRef.current.querySelector(`.${OVERLAY_GUIDE_CLASS}`)
    position(guide, offsetX, offsetY)

    const gridList = magnifierOverlayRef.current.querySelectorAll(`.${OVERLAY_GRID_CLASS}`)
    gridList.forEach((grid) => {
      position(grid, offsetX, offsetY)
    })

    const pathList = magnifierOverlayRef.current.querySelectorAll(`.${SHOW_PATH_CLASS}`)
    pathList.forEach((path) => {
      position(path, offsetX, offsetY)
    })
  }

  const onMouseEnter = () => {
    if (!isMagnifierActiveRef.current) return
    magnifierOverlayRef.current.style.display = 'block'
  }

  const onMouseLeave = () => {
    if (!isMagnifierActiveRef.current) return
    magnifierOverlayRef.current.style.display = 'none'
  }

  const initMagnifier = () => {
    if (isMagnifierInitRef.current) return

    isMagnifierInitRef.current = true

    const imageWidth = imageSizesRef.current.width || DEFAULT_OVERLAY_IMAGE_SIZES.width
    const imageHeight = imageSizesRef.current.height || DEFAULT_OVERLAY_IMAGE_SIZES.height

    const magnifierImage = magnifierOverlayRef.current.querySelector(`#${OVERLAY_IMG_CLASS}`)

    resize(magnifierImage, imageWidth, imageHeight)

    const gridList = magnifierOverlayRef.current.querySelectorAll(`.${OVERLAY_GRID_CLASS}`)
    gridList.forEach((grid) => {
      resizeSvg(grid, imageWidth, imageHeight)
    })

    const pathList = magnifierOverlayRef.current.querySelectorAll(`.${SHOW_PATH_CLASS}`)
    pathList.forEach((path) => {
      resizeSvg(path, imageWidth, imageHeight)
    })

    const guide = magnifierOverlayRef.current.querySelector(`.${OVERLAY_GUIDE_CLASS}`)
    resizeSvg(guide, imageWidth, imageHeight)
  }

  const handleMagnifier = () => {
    magnifierOverlayRef.current.innerHTML = overlayImageWrapRef.current.innerHTML
    isMagnifierActiveRef.current = !isMagnifierActiveRef.current
    setIsMagnifierActive(isMagnifierActiveRef.current)

    if (isMagnifierActiveRef.current) {
      setIsDrawingActive(false)
      overlayImageWrapRef.current.addEventListener('mousemove', onMouseMove)
      overlayImageWrapRef.current.addEventListener('mouseenter', onMouseEnter)
      overlayImageWrapRef.current.addEventListener('mouseleave', onMouseLeave)
      initMagnifier()
    } else {
      setIsDrawingActive(true)
      isMagnifierInitRef.current = false
      overlayImageWrapRef.current.removeEventListener('mousemove', onMouseMove)
      overlayImageWrapRef.current.removeEventListener('mouseenter', onMouseEnter)
      overlayImageWrapRef.current.removeEventListener('mouseleave', onMouseLeave)
      magnifierOverlayRef.current.style.display = 'none'
    }
  }

  const handleOverlayZoom = () => {
    isToFullSize.current = !isToFullSize.current
    resizeAndPositionLayers()
  }

  useEffect(() => {
    if (!layoutState.isOverlayOpened) return

    resizeAndPositionLayers()
    if (isMagnifierActiveRef.current) handleMagnifier()
  }, [
    orderState.overlayImgIndex,
    overlaySrc,
  ])

  useEffect(() => {
    const comments = orderState.images.flatMap((image) => image.image_comments)
    const uniqueComments = Array.from(
      new Map(comments.map((comment) => [comment.comment, comment])).values(),
    )
    setCommentSuggestions(uniqueComments)
  }, [orderState.images[orderState.overlayImgIndex].image_comments])

  const resizeLayersOnWindowResize = debounce(() => resizeAndPositionLayers())

  useEffect(() => {
    if (!layoutState.isOverlayOpened) {
      return () => {
      }
    }

    window.addEventListener('resize', resizeLayersOnWindowResize)

    overlayImageWrapRef.current.addEventListener('wheel', onMouseWheel)

    getDetails()

    dragEventRef.current = dragElement(overlayImageWrapRef.current, isSpaceRequired)
    overlayImageWrapRef.current.addEventListener('mousedown', dragEventRef.current)

    // cleanup: "remove event listeners" and "intervals" to avoid memory leaks by creating the same listeners
    return () => {
      window.removeEventListener('resize', resizeLayersOnWindowResize)
    }
  }, [layoutState.isOverlayOpened])

  useEffect(() => {
    const src = getOverlaySrc()
    if (src === overlaySrc) return // skip duplicated update of image src is heavy browser operation
    // console.log('src', src.split('?')[0])
    setOverlaySrc(src)
  }, [
    isOriginalImage,
    imageElements,
    orderState.images,
    orderState.overlayImgIndex,
    orderState.selectedLayers,
    orderState.layerUrls,
  ])

  useEffect(() => {
    imageSizesRef.current = getImageSizes()
  }, [
    isOriginalImage,
    imageElements,
    orderState.images,
    orderState.overlayImgIndex,
  ])

  const onContrastChange = ({ min, max }) => {
    if (min === minContrast && max === maxContrast) {
      overlayImgContrastRef.current.style.display = 'none'
    } else {
      overlayImgContrastRef.current.style.display = 'inline-block'
    }

    if (!originalImageDataRef.current) return // Ensure original data is available

    const adjust = (value, low, high) => {
      const range = high - low
      return Math.max(
        minContrast,
        Math.min(
          maxContrast,
          ((value - low) * maxContrast) / range,
        ),
      )
    }

    const imageData = contrastCtxRef.current.createImageData(originalImageDataRef.current)

    for (let i = 0; i < originalImageDataRef.current.data.length; i += 4) {
      imageData.data[i] = adjust(originalImageDataRef.current.data[i], min, max) // Red
      imageData.data[i + 1] = adjust(
        originalImageDataRef.current.data[i + 1],
        min,
        max,
      ) // Green
      imageData.data[i + 2] = adjust(
        originalImageDataRef.current.data[i + 2],
        min,
        max,
      ) // Blue
      imageData.data[i + 3] = originalImageDataRef.current.data[i + 3] // Alpha
    }

    // Draw the modified image data to the canvas
    contrastCtxRef.current.putImageData(imageData, 0, 0)
  }

  return (
    <>
      <div
        id="image-overlay"
        className="image-overlay"
        data-is-overlay="1"
        data-allowed-to-comment="1"
        data-is-shapes="1"
        style={(layoutState.isOverlayOpened) ? {} : { display: 'none' }}
      >
        <div
          className="image-overlay--left"
          style={{
            display: showInterface ? 'flex' : 'none',
          }}
        >
          <div className="image-overlay--left__top scrollbar-overflow">
            <div
              className={`image-overlay--left__title ${isExpanded.imageInformation ? EXPANDED_CLASS : ''}`}
              onClick={() => expand('imageInformation')}
            >
              <div className="image-overlay__title-text">
                {Translation.image_information}
                <OverlayLeftArrowIconSvg
                  className={`title-icon 
                  ${isExpanded.imageInformation ? EXPANDED_CLASS : ''}
                  ${(!isExpanded.imageInformation && (showFileName() || showImageDimensions()
                    || showColorSpace() || showBackgroundColor())) ? 'notice' : ''}`}
                />
              </div>
            </div>

            {
              (isExpanded.imageInformation || showFileName() || showImageDimensions()
                || showColorSpace() || showBackgroundColor()) && (
                <div
                  className="image-overlay--left__content scrollbar-overflow scrollbar-overflow__small word-break"
                >
                  {isExpanded.imageInformation && (
                    <Typography
                      label={
                        `${orderState.images[orderState.overlayImgIndex].original_image_id
                          ? orderState.images[orderState.overlayImgIndex].original_image_id
                          : orderState.images[orderState.overlayImgIndex].id}`
                      }
                    />
                  )}

                  {showFileName() && (
                    <ImageFileName
                      isOriginalImage={isOriginalImage}
                      originalImageDetails={originalImageDetails}
                      image={orderState.images[orderState.overlayImgIndex]}
                      removeErrorCode={removeErrorCode}
                    />
                  )}

                  {showImageDimensions() && (
                    <ImageRatio
                      isOriginalImage={isOriginalImage}
                      image={orderState.images[orderState.overlayImgIndex]}
                    />
                  )}

                  {showImageDimensions() && (
                    <ImageDimensions
                      isOriginalImage={isOriginalImage}
                      originalImageDetails={originalImageDetails}
                      image={orderState.images[orderState.overlayImgIndex]}
                      removeErrorCode={removeErrorCode}
                    />
                  )}

                  {showColorSpace() && (
                    <ImageColorSpace
                      isOriginalImage={isOriginalImage}
                      originalImageDetails={originalImageDetails}
                      image={orderState.images[orderState.overlayImgIndex]}
                      removeErrorCode={removeErrorCode}
                    />
                  )}

                  {showBackgroundColor() && (
                    <ImageBackgroundColor
                      isOriginalImage={isOriginalImage}
                      originalImageDetails={originalImageDetails}
                      image={orderState.images[orderState.overlayImgIndex]}
                      removeErrorCode={removeErrorCode}
                    />
                  )}

                  {showColorDepth() && (
                    <ImageColorDepth
                      isOriginalImage={isOriginalImage}
                      originalImageDetails={originalImageDetails}
                      image={orderState.images[orderState.overlayImgIndex]}
                    />
                  )}
                </div>
              )
            }

            <div
              className={`image-overlay--left__title ${isExpanded.contrast ? EXPANDED_CLASS : ''}`}
              onClick={() => expand('contrast')}
            >
              <div className="image-overlay__title-text">
                {Translation.contrast}
                <OverlayLeftArrowIconSvg
                  className={`title-icon ${isExpanded.contrast ? EXPANDED_CLASS : 'title-icon'}`}
                />
              </div>
            </div>

            {isExpanded.contrast && (
              <div
                className="image-overlay--left__content scrollbar-overflow scrollbar-overflow__small"
              >
                <DualRange
                  max={maxContrast}
                  min={minContrast}
                  values={contrastValue}
                  onChange={onContrastChange}
                  leftLabel={Translation.shadows}
                  rightLabel={Translation.lights}
                />
              </div>
            )}

            <div
              className={`image-overlay--left__title ${isExpanded.background ? EXPANDED_CLASS : ''}`}
              onClick={() => expand('background')}
            >
              <div className="image-overlay__title-text">
                {Translation.background}
                <OverlayLeftArrowIconSvg
                  className={`title-icon ${isExpanded.background ? EXPANDED_CLASS : 'title-icon'}`}
                />
              </div>
            </div>

            {isExpanded.background && (
              <div
                className="image-overlay--left__content scrollbar-overflow scrollbar-overflow__small"
              >
                <Background />
              </div>
            )}

            <div
              className={`image-overlay--left__title ${isExpanded.grids ? EXPANDED_CLASS : ''}`}
              onClick={() => expand('grids')}
            >
              <div className="image-overlay__title-text">
                {Translation.grids}
                <OverlayLeftArrowIconSvg
                  className={`title-icon ${isExpanded.grids ? EXPANDED_CLASS : 'title-icon'}`}
                />
              </div>
            </div>

            {isExpanded.grids && (
              <div
                className="image-overlay--left__content scrollbar-overflow scrollbar-overflow__small"
              >
                <div className="grid-list-container">
                  {orderState.gallery?.grids?.length > 0 && (
                    orderState.gallery?.grids.map((grid, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <div className="grid-list-item" key={index}>
                        <div
                          className="grid-item-color"
                          style={{
                            backgroundColor:
                              orderState.selectedGrids.some((selectedGrid) => selectedGrid.name === grid.name)
                                ? getGridColor(index)
                                : null,
                          }}
                        />
                        <Checkbox
                          label={(
                            <span className="overflow-ellipsis">
                              {grid.name || grid.name_short}
                              {' '}
                              {grid.template_name && (
                                <span className="grid-item-template-name">{grid.template_name}</span>
                              )}
                            </span>
                          )}
                          id={`grid-overlay-${orderState.gallery?.grids?.findIndex((g) => g.name === grid.name)}`}
                          checked={orderState.selectedGrids.some((selectedGrid) => selectedGrid.name === grid.name)}
                          onChange={() => onSelectGrid(grid)}
                        />
                      </div>
                    ))
                  )}
                </div>
              </div>
            )}

            {Object.keys(orderState.images[orderState.overlayImgIndex].path)?.length > 0 && (
              <>
                <div
                  className={`image-overlay--left__title ${isExpanded.paths ? EXPANDED_CLASS : ''}`}
                  onClick={() => expand('paths')}
                >
                  <div className="image-overlay__title-text">
                    {Translation.paths}
                    <OverlayLeftArrowIconSvg
                      className={`title-icon ${isExpanded.paths ? EXPANDED_CLASS : 'title-icon'}`}
                    />
                  </div>
                </div>

                {isExpanded.paths && (
                  <div
                    className="image-overlay--left__content scrollbar-overflow scrollbar-overflow__small"
                  >
                    <Typography
                      fontSize={PX_TO_REM['12']}
                      label={Translation.path_width}
                    />
                    <Range
                      value={orderState.pathRange}
                      min={PATH_WIDTH_MIN}
                      max={PATH_WIDTH_MAX}
                      showLabels
                      onChange={(e) => onChangePathRange(e.target.value)}
                    />

                    <div className="path-list">
                      {
                        Object.keys(imagePaths).map((path, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <div className="path-item" key={index}>
                            <div
                              className="path-color"
                              style={{
                                backgroundColor:
                                  orderState.selectedImagePaths?.[orderState.images[orderState.overlayImgIndex].id]?.[path]
                                    ? layoutState.pathsColors[path]
                                    : null,
                              }}
                              onClick={() => handlePathColorClick(path)}
                            >
                              {openColorPicker && colorSelectingPath === path && (
                                <ColorPicker
                                  onColorChange={handlePathColorSelect}
                                  closePicker={closePicker}
                                  colors={PATH_COLORS}
                                />
                              )}
                            </div>
                            <Checkbox
                              label={path}
                              checked={
                                orderState.selectedImagePaths?.[orderState.images[orderState.overlayImgIndex].id]?.[path]
                              }
                              id={path}
                              onChange={() => onSelectPath(path)}
                            />
                            {imagePaths[path]?.line.viewbox && (
                              <div
                                className="path-outside-image"
                                data-viewbox={imagePaths[path]?.line.viewbox}
                                data-viewbox-ratio={imagePaths[path]?.line.viewbox_ratio}
                                data-id={orderState.images[orderState.overlayImgIndex].id}
                                data-name={path}
                                onClick={clickPathOutsideImage}
                              >
                                <PathOutsideImageIconSvg />
                              </div>
                            )}
                          </div>
                        ))
                      }

                    </div>
                  </div>
                )}
              </>
            )}

            {orderState.images[orderState.overlayImgIndex]?.layer?.length > 0 && (
              <>
                <div
                  className={`image-overlay--left__title ${isExpanded.layers ? EXPANDED_CLASS : ''}`}
                  onClick={() => expand('layers')}
                >
                  <div className="image-overlay__title-text">
                    {Translation.layers}
                    <OverlayLeftArrowIconSvg
                      className={`title-icon ${isExpanded.layers ? EXPANDED_CLASS : 'title-icon'}`}
                    />
                  </div>
                </div>

                {isExpanded.layers && (
                  <div
                    className="image-overlay--left__content scrollbar-overflow scrollbar-overflow__small"
                  >
                    <div className="layers-container">
                      <RadioGroup
                        values={layerValues}
                        // onChange={onHandleLayerChange}
                        value={
                          orderState.selectedLayers[orderState.images[orderState.overlayImgIndex].id]
                          || `${ALL_LAYERS_KEY}-${orderState.images[orderState.overlayImgIndex].id}`
                        }
                      />
                    </div>
                  </div>
                )}
              </>
            )}

            <div
              className={`image-overlay--left__title ${isExpanded.alphaChannel ? EXPANDED_CLASS : ''}`}
              onClick={() => expand('alphaChannel')}
            >
              <div className="image-overlay__title-text">
                {Translation.alpha_channel}
                <OverlayLeftArrowIconSvg
                  className={`title-icon ${isExpanded.alphaChannel ? EXPANDED_CLASS : 'title-icon'}`}
                />
              </div>
            </div>

            {isExpanded.alphaChannel && (
              <div
                className="image-overlay--left__content scrollbar-overflow scrollbar-overflow__small"
              >
                <div className="alpha-channel-list">
                  <div className="alpha-channel-item">
                    <Toggle
                      checked={false}
                      onChange={() => {
                      }}
                      id="alpha-chanel-1"
                    />
                    <LayerMaskIconSvg />
                    <Typography label="Layer name" />
                  </div>
                  <div className="alpha-channel-item">
                    <Toggle
                      checked
                      onChange={() => {
                      }}
                      id="alpha-chanel-2"
                    />
                    <LayerMaskIconSvg />
                    <Typography label="Layer name" />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div
          className="image-overlay--middle"
          style={{ backgroundColor: orderState.galleryBackground }}
          ref={overlayContainerRef}
        >
          {userState.user.is_admin && orderState?.orderViewingUsers?.length && showInterface
            ? <OverlayAvatars />
            : null}
          <div
            ref={overlayImageWrapRef}
            id={OVERLAY_WRAP_CLASS}
            className={OVERLAY_WRAP_CLASS}
            style={{
              width: `${overlayImgSize.width}px`,
              height: `${overlayImgSize.height}px`,
              ...((isBroken || isNotFound) && {
                width: `${DEFAULT_OVERLAY_IMAGE_SIZES.width}px`,
                height: `${DEFAULT_OVERLAY_IMAGE_SIZES.height}px`,
              }),
              ...(orderState.previewsBackground === TRANSPARENT_KEY
                ? { backgroundImage: `url(${TRANSPARENT_BG})` }
                : { backgroundColor: orderState.previewsBackground }),
            }}
          >
            <img
              id={OVERLAY_IMG_CLASS}
              ref={overlayImgRef}
              src={overlaySrc}
              onLoad={handleOverlayImgLoad}
              onError={handleOverlayImgLoadError}
              className={`${OVERLAY_IMG_CLASS} ${SKELETON_CLASS} ${NOSELECT_CLASS}`}
              style={{
                width: `${overlayImgSize.width}px`,
                height: `${overlayImgSize.height}px`,
                ...((isBroken || isNotFound) && {
                  width: `${DEFAULT_OVERLAY_IMAGE_SIZES.width}px`,
                  height: `${DEFAULT_OVERLAY_IMAGE_SIZES.height}px`,
                  backgroundColor: 'transparent',
                }),
              }}
              alt=""
            />

            {orderState.selectedUnderlayUrl && (
              <img
                id={OVERLAY_UNDERLAY_CLASS}
                ref={overlayUnderlayRef}
                className={OVERLAY_UNDERLAY_CLASS}
                src={orderState.selectedUnderlayUrl}
                alt=""
              />
            )}

            <svg
              width={orderState.images[orderState.overlayImgIndex]?.width || DEFAULT_OVERLAY_IMAGE_SIZES.width}
              height={orderState.images[orderState.overlayImgIndex]?.height || DEFAULT_OVERLAY_IMAGE_SIZES.height}
              viewBox={`0 0 
                ${orderState.images[orderState.overlayImgIndex]?.width || DEFAULT_OVERLAY_IMAGE_SIZES.width} 
                ${orderState.images[orderState.overlayImgIndex]?.height || DEFAULT_OVERLAY_IMAGE_SIZES.height}`}
              className={OVERLAY_GUIDE_CLASS}
              style={{
                width: `${overlayImgSize.width}px`,
                height: `${overlayImgSize.height}px`,
              }}
            />

            <svg
              id={OVERLAY_DRAWING_CLASS}
              className={OVERLAY_DRAWING_CLASS}
              ref={overlayImgDrawingRef}
              width={orderState.images[orderState.overlayImgIndex]?.width || DEFAULT_OVERLAY_IMAGE_SIZES.width}
              height={orderState.images[orderState.overlayImgIndex]?.height || DEFAULT_OVERLAY_IMAGE_SIZES.height}
              viewBox={`0 0 
                ${orderState.images[orderState.overlayImgIndex]?.width || DEFAULT_OVERLAY_IMAGE_SIZES.width} 
                ${orderState.images[orderState.overlayImgIndex]?.height || DEFAULT_OVERLAY_IMAGE_SIZES.height}`}
              style={{
                width: `${overlayImgSize.width}px`,
                height: `${overlayImgSize.height}px`,
              }}
            />

            <canvas
              id={OVERLAY_CONTRAST_CLASS}
              ref={overlayImgContrastRef}
              width={overlayImgSize.width}
              height={overlayImgSize.height}
              style={{
                display: 'none',
                width: `${overlayImgSize.width}px`,
                height: `${overlayImgSize.height}px`,
              }}
            />
          </div>
        </div>

        <div
          className="image-overlay--right"
          style={{
            display: showInterface ? 'flex' : 'none',
          }}
        >
          <div className="image-overlay--navigation">
            <div className="image-overlay--navigation__wrap">
              {orderState.imageType !== IMAGE_TYPES.input && (
                <Tooltip
                  text={isOriginalImage ? Translation.show_output_image : Translation.show_input_image}
                  position="right"
                >
                  <div
                    id="image-overlay__compare"
                    className={`image-overlay__control image-overlay__compare 
                      ${NOSELECT_CLASS} ${isOriginalImage ? ACTIVE_CLASS : ''}`}
                    onClick={toggleOriginalImage}
                  >
                    <OverlayCompareIconSvg />
                  </div>
                </Tooltip>
              )}
              <Tooltip
                text={isToFullSize.current ? Translation.fit_to_screen : Translation.show_full_size}
                position="right"
              >
                <div
                  id="image-overlay__zoom"
                  onClick={handleOverlayZoom}
                  className={`image-overlay__control image-overlay__zoom ${NOSELECT_CLASS}`}
                >
                  {zoomValue}
                  %
                </div>
              </Tooltip>
            </div>

            <div className="image-overlay--navigation__wrap">
              <Tooltip
                text={isDrawingActive ? Translation.disable_drawing : Translation.enable_drawing}
                position="left"
              >
                <div
                  id="image-overlay__drawing"
                  className={`image-overlay__control image-overlay__drawing 
                   ${isDrawingActive ? ACTIVE_CLASS : ''}
                   ${NOSELECT_CLASS}`}
                  onClick={handleOverlayDrawingClick}
                >
                  <OverlayDrawingIconSvg />
                </div>
              </Tooltip>

              <Tooltip
                text={!isSpaceRequired ? Translation.disable_dragging : Translation.enable_dragging}
                position="left"
              >
                <div
                  id="image-overlay__drag"
                  className={`image-overlay__control image-overlay__drag 
                    ${NOSELECT_CLASS} ${!isSpaceRequired ? ACTIVE_CLASS : ''}`}
                  onClick={toggleSpaceRequired}
                >
                  <OverlayDragIconSvg />
                </div>
              </Tooltip>

              <Tooltip
                text={isMagnifierActive ? Translation.disable_magnifier : Translation.enable_magnifier}
                position="left"
              >
                <div
                  id="image-overlay__magnify"
                  className={`image-overlay__control image-overlay__magnify 
                    ${NOSELECT_CLASS} 
                    ${isMagnifierActive ? ACTIVE_CLASS : ''}`}
                  onClick={handleMagnifier}
                >
                  <OverlayMagnifyIconSvg />
                </div>
              </Tooltip>
            </div>

            <div className="image-overlay--navigation__wrap">
              <div
                id="image-overlay__prev"
                onClick={handleNextPrevClick}
                data-direction="-1"
                className={`image-overlay__control image-overlay__prev 
                  ${(orderState.overlayImgIndex === 0) ? DISABLED_CLASS : ''} 
                  ${NOSELECT_CLASS}`}
              >
                <OverlayPrevIconSvg />
              </div>

              <div
                id="image-overlay__next"
                onClick={handleNextPrevClick}
                data-direction="1"
                className={`image-overlay__control image-overlay__next 
                  ${(orderState.overlayImgIndex === (orderState.images?.length || 0) - 1) ? DISABLED_CLASS : ''} 
                  ${NOSELECT_CLASS}`}
              >
                <OverlayNextIconSvg />
              </div>
            </div>

            <div className="image-overlay--images-counter">
              <span id="image-overlay--images-counter__current">{(orderState.overlayImgIndex + 1)}</span>
              <span className="image-overlay--images-counter__separator">/</span>
              <span id="image-overlay--images-counter__total">{orderState.images?.length}</span>
            </div>
          </div>

          <div className="image-overlay--right__top scrollbar-overflow">
            {(isHistory || isVersions) && (
              <>
                <div className="image-overlay--left__title">
                  <div className={`image-overlay__title-text ${DISABLED_CLASS}`}>
                    {Translation.history}
                    <span
                      className="image-overlay--history-toggle"
                      onClick={() => expand('history')}
                    >
                      {isExpanded.history ? Translation.hide_comments_history : Translation.show_comments_history}
                    </span>
                  </div>
                </div>

                <div
                  ref={historyWrapRef}
                  className={`image-overlay--right__content 
                    ${historyItemsCount.current > 2 ? 'scrollbar-overflow scrollbar-overflow__small' : ''}`}
                >
                  <HistoryOverlay
                    type={isExpanded.history ? 'history' : 'versions'}
                    overlayImgDrawingRef={overlayImgDrawingRef}
                    historyWrapRef={historyWrapRef}
                  />
                </div>
              </>
            )}

            {orderState.gallery?.is_allowed_to_comment && commentSuggestions.length > 0 ? (
              <>
                <div
                  className={`image-overlay--left__title ${isExpanded.commentSuggestion ? EXPANDED_CLASS : ''}`}
                  onClick={() => expand('commentSuggestion')}
                >
                  <div className="image-overlay__title-text">
                    {Translation.comment_suggestions}
                    <OverlayLeftArrowIconSvg
                      className={`title-icon ${isExpanded.commentSuggestion ? EXPANDED_CLASS : 'title-icon'}`}
                    />
                  </div>
                </div>

                {isExpanded.commentSuggestion && (
                  <div
                    className="image-overlay--right__content scrollbar-overflow scrollbar-overflow__small"
                  >
                    {commentSuggestions.filter(
                      (c) => c.comment.toLocaleLowerCase().includes(orderState.imageCommentText.toLocaleLowerCase()),
                    ).map((imageComment) => (
                      <CommentSuggestionOverlay key={imageComment.id} comment={imageComment} />
                    ))}
                  </div>
                )}
              </>
            ) : null}

            {(orderState.images[orderState.overlayImgIndex]
              && orderState.images[orderState.overlayImgIndex].image_comments?.length > 0) && (
              <>
                <div className="image-overlay--left__title">
                  <div className={`image-overlay__title-text ${DISABLED_CLASS}`}>
                    {Translation.active_comments}
                  </div>
                </div>

                {orderState.images[orderState.overlayImgIndex].image_comments.map((imageComment) => (
                  <CommentOverlay
                    key={imageComment.id}
                    comment={imageComment}
                    overlayImgDrawingRef={overlayImgDrawingRef}
                    refreshGallery={refreshGallery}
                    resetInterval={resetInterval}
                  />
                ))}
              </>
            )}
          </div>

          {orderState.gallery?.is_allowed_to_comment ? (
            <Comment
              refreshGallery={refreshGallery}
              resetInterval={resetInterval}
              resizeRatioRef={resizeRatioRef}
              overlayImgDrawingRef={overlayImgDrawingRef}
              isSpaceRequired={isSpaceRequired}
              isDrawingActive={isDrawingActive}
            />
          ) : null}
        </div>

        <div onClick={onCloseOverlay} id="image-overlay__close" className="image-overlay__close">
          <OverlayCloseIconSvg />
        </div>

        {layoutState.isShortcutsOpened && (
          <Shortcuts />
        )}

        <div className="image-overlay--left__bottom">
          <div className="image-overlay--left__buttons">
            <div
              className={`image-overlay--left__button ${showInterface ? ACTIVE_CLASS : ''}`}
              onClick={() => toggleInterface()}
            >
              <CategoryIconSvg />
              {Translation.interface}
            </div>
            <div
              className={`image-overlay--left__button ${layoutState.isShortcutsOpened ? ACTIVE_CLASS : ''}`}
              onClick={() => toggleShortcuts()}
            >
              <RocketIconSvg />
              {Translation.shortcuts}
            </div>
            <div
              className={
                `image-overlay--left__button 
                ${orderState.gallery?.is_send_into_redo ? '' : DISABLED_CLASS}`
              }
              onClick={() => sendInRedo()}
            >
              <RedoIconSvg />
              {Translation.redo}
            </div>
          </div>
        </div>

        {!showInterface && (
          <>
            <CollapsibleCommentOverlay
              image={orderState.images[orderState.overlayImgIndex]}
              refreshGallery={refreshGallery}
              resetInterval={resetInterval}
              resizeRatioRef={resizeRatioRef}
              overlayImgDrawingRef={overlayImgDrawingRef}
              isSpaceRequired={isSpaceRequired}
              isDrawingActive={isDrawingActive}
            />

            <CollapsibleImageInfo
              image={orderState.images[orderState.overlayImgIndex]}
              refreshGallery={refreshGallery}
              resetInterval={resetInterval}
              isOriginalImage={isOriginalImage}
              originalImageDetails={originalImageDetails}
            />
          </>
        )}
      </div>

      <div
        id="magnifier-overlay"
        className="magnifier-overlay"
        ref={magnifierOverlayRef}
      />
    </>
  )
}

Overlay.propTypes = {
  refreshGallery: PropTypes.func,
  resetInterval: PropTypes.func,
}

Overlay.defaultProps = {
  refreshGallery: () => {
  },
  resetInterval: () => {
  },
}

export default Overlay
